<section class="home-news-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-home-news-post-card mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/arama/e-learning/elearning-1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <div class="tag">
                            <a>Business</a>
                        </div>
                        <ul class="post-meta">
                            <li class="post-author">
                                By: <a class="d-inline-block">Sanjay</a>
                            </li>
                            <li class="date"><i class='bx bxs-calendar'></i> December 25, 2022</li>
                        </ul>
                        <h3><a class="d-inline-block">Commencement of Business Certificate</a></h3>
                        <a class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-home-news-post-card mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/arama/e-learning/elearning-2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <div class="tag">
                            <a>GST</a>
                        </div>
                        <ul class="post-meta">
                            <li class="post-author">
                                By: <a class="d-inline-block">Gauri</a>
                            </li>
                            <li class="date"><i class='bx bxs-calendar'></i> January 10, 2023</li>
                        </ul>
                        <h3><a class="d-inline-block">Utilization Input Tax Credit Under GST</a></h3>
                        <a class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-home-news-post-card mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/arama/e-learning/elearning-3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <div class="tag">
                            <a>Tax</a>
                        </div>
                        <ul class="post-meta">
                            <li class="post-author">
                                By: <a class="d-inline-block">Aaron</a>
                            </li>
                            <li class="date"><i class='bx bxs-calendar'></i> January 20, 2023</li>
                        </ul>
                        <h3><a class="d-inline-block">Additional Requirement In Income Tax Return Forms</a></h3>
                        <a class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
