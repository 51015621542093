<div class="blog-detail-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-detail-desc">
                    <div class="article-image">
                        <img src="assets/img/blog/blog1.jpg" alt="image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <i class='bx bx-folder-open'></i>
                                    <span>Category</span>
                                    <a routerLink="/single-blog">Design</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Viewed</span>
                                    <a routerLink="/single-blog">13,454</a>
                                </li>
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Last Updated</span>
                                    <a routerLink="/single-blog">01/14/2019</a>
                                </li>
                            </ul>
                        </div>
                        <h3>Most Popular Education Posts Of The Week 20-26 Aug</h3>
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                        <blockquote class="wp-block-quote">
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                            <cite>Tom Cruise</cite>
                        </blockquote>
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                        <h3>Four major elements that we offer:</h3>
                        <ul class="features-list">
                            <li><i class='bx bx-check-double'></i> Scientific Skills For getting a better result</li>
                            <li><i class='bx bx-check-double'></i> Communication Skills to getting in touch</li>
                            <li><i class='bx bx-check-double'></i> A Career Overview opportunity Available</li>
                            <li><i class='bx bx-check-double'></i> A good Work Environment For work</li>
                        </ul>
                        <h3>Setting the mood with incense</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                        <h3>The Rise Of Smarketing And Why You Need It</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 10, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 21, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">I Used The Web For A Day On A 50 MB Budget</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">How To Create A Responsive Popup Gallery?</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>FREE CONSULTATION</span>
                            <a href="tel:9000090000">9000090000</a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>