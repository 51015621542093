<div class="feedback-slides">
    <owl-carousel-o [options]="feedbackSlides">
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“As a potential new business owner or existing company/sole trader enough as the perfect support for your new venture  with an extremely approachable and professional manner.”</p>
                <div class="info">
                    <h3>Richard</h3>
                    <span>Chennai</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“The whole process was extremely smooth and I was very impressed with the results. Never again will I attempt a tax return on my own; she has saved me her fee many times over!”</p>
                <div class="info">
                    <h3>Rakesh</h3>
                    <span>Nagercoil</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“Arama Management provided an exceptional service to us in making a successful tax credits. We were very impressed and have no hesitation in highly recommending them.”</p>
                <div class="info">
                    <h3>Saravanan</h3>
                    <span>Chennai</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-feedback-item">
                <p>“I have a real sense of security, knowing there’s always quality advice on hand as my business grows and I have to make more decisions.”</p>
                <div class="info">
                    <h3>Mohan</h3>
                    <span>Nagercoil</span>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
