import { Component, Input, OnInit } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';

@Component({
  selector: 'app-service-pricing',
  templateUrl: './service-pricing.component.html',
  styleUrls: ['./service-pricing.component.scss']
})
export class ServicePricingComponent implements OnInit {

  @Input() servicePricings: Pricing[];
  constructor() { }

  ngOnInit(): void {
  }

}
