import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-msme-registration',
  templateUrl: './msme-registration.component.html',
  styleUrls: ['./msme-registration.component.scss']
})
export class MsmeRegistrationComponent {

    descriptionTitle = 'MSME Registration';
    descriptionContent = 'Our Professionals will help you in getting your MSME and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'MSME (Micro, Small and Medium Enterprises) Act., is formed by the government for the purpose of promoting Entrepreneurship by providing special benefits to the small businesses in the form of incentives and subsidies. These businesses also get loans at concession rate to set up their businesses. To avail such benefits, the small businesses need to register under MSMEs.'
    ]
    detailDocumentArray = [
        'Identity Proof',
        'Business Address Proof',
        'PAN Card',
        'Business Registration Certificate'
    ];
    detailBenefitArray = [
        'Cheaper bank loans,',
        'Tax benefits',
        'Preference during the tender process',
        'Access to various schemes and incentives of government'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your MSME registration form'
        },
        {
            item1: 'Gathering Documents',
            item2: 'We will collect the necessary documents from you for registration process'
        },
        {
            item1: 'Application Submission',
            item2: 'Once gathering the necessary documents and verification we will submit application'
        },
        {
            item1: 'Certificate Issue',
            item2: 'Certificate will be issued by the respective authority person after approval'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'What are the categories under MSME?',
            item2: 'Micro Enterprises, Small Enterprises and Medium Enterprises.'
        },
        {
            item1: 'Which entity comes under Micro Enterprises?',
            item2: 'In case of Micro Manufacturing Enterprises - Enterprise invested less than INR 25 lac in plant and Machineries. In case of Micro Service Enterprises - Enterprise Invested less than INR 10 lac in plant and Machineries excluding land and buildings.'
        },
        {
            item1: 'Which entity comes under Small Enterprises?',
            item2: 'In case of Micro Manufacturing Enterprises - Enterprise invested more than INR 25 lac and less than INR 5 crore in plant and Machineries. In case of Micro Service Enterprises - Enterprise Invested more than INR 10 lac and less than INR 2 crore in plant and Machineries excluding land and buildings.'
        },
        {
            item1: 'Which Entity comes under Medium Enterprises?',
            item2: 'In case of Micro Manufacturing Enterprises - Enterprise invested more than INR 5 Crore and less than INR 10 Crore in plant and Machineries. In case of Micro Service Enterprises - Enterprise Invested more than INR 2 Crore and less than INR 5 Crore in plant and Machineries excluding land and buildings.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 999,
            charge: 'all inclusive fees',
            features: [
                'Registered PF'
            ]
        }
    ]

}
