import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-esi-registration',
  templateUrl: './esi-registration.component.html',
  styleUrls: ['./esi-registration.component.scss']
})
export class EsiRegistrationComponent {

    descriptionTitle = 'ESI Registration';
    descriptionContent = 'Our Professionals will help you in getting your ESI and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Employees State Insurance(ESI) is a independent social security and health insurance scheme for Indian workers. For employees employing INR 21,000 or less per month, the contribution of employer 4.75 percentage and contribution of employee will be 1.75 percentage, total share  as 6.5 percentage.',
        'This reserve is controlled by the ESI Corporation (ESI) and according to the guidelines of ESI Act 1948, which manages  the provisions of medical and cash benefits to the employees and their family through its network of sub offices, dispensaries and hospitals throughout the country. Mainly the contribution of ESI is self-governed to corporation under Ministry of Labour and Employment, Government of India. But most of the clinic and medical institutions or hospitals are run by their own state governments.'
    ]
    detailDocumentArray = [
        'Form 01 Employers Registration (may be obtained from ESI Office)',
        'List of minimum 10/20 Employees with their DOJ and Rate of Salary',
        'Copy of PAN Bank a/c existing Certificate',
        'VAT Registration Copy',
        'Factory/Shop & Comm. Est Registration Copy of',
        'Memorandum & Articles of Association with Certificate of Incorporation/Partnersheep Deed as applicable',
        'Declaration Form of each employees along with their 2 Postcard size photographs (Optional)',
        'DD/Cheque for Six month advance contribution (Optional)'
    ];
    detailBenefitArray = [
        'Complete medical care to all persons registered under ESI and their family members',
        'Retired and permanently disabled insured persons and their spouses are provided with medical care on payment of Rs.120/- of a token annual premium.'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your ESI registration form'
        },
        {
            item1: 'Gathering Documents',
            item2: 'Will gather all documents for the registration process'
        },
        {
            item1: 'Form Submission',
            item2: 'For verification we will submit the details to respective department'
        },
        {
            item1: 'ESI Number',
            item2: 'After the successful registration we will provide the ESI number'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'What will be the Contribution for ESI on the side of Employer and Employee?',
            item2: '1.75% need to contribute by employee and another 4.75 percentage by employer and totally it will come as 6.5 percentage.'
        },
        {
            item1: 'Is it mandatory for all workers to contribute to the ESI?',
            item2: 'No, It is not mandatory for all workers but employee who has less than 21,000 salary should have.'
        },
        {
            item1: 'Form that has to be filled to be a member of ESI Registration?',
            item2: 'Nomination Form No 2.'
        },
        {
            item1: 'What form has to be filled to transfer ESI deposit?',
            item2: 'Form no 13 has to be filed.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 4999,
            charge: 'all inclusive fees',
            features: [
                'Registered ESI'
            ]
        }
    ]
}
