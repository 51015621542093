import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-description',
  templateUrl: './service-description.component.html',
  styleUrls: ['./service-description.component.scss']
})
export class ServiceDescriptionComponent implements OnInit {
  @Input() serviceDescriptionTitle: string;
  @Input() serviceDescriptionContent: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
