import { Component, Input, OnInit } from '@angular/core';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit {
  @Input() serviceDetailDescriptions: string[];
  @Input() serviceDetailDocuments: string[];
  @Input() serviceDetailBenefits: string[];
  @Input() serviceDetailWeDos: TwoDataSet[];

  constructor() { }

  ngOnInit(): void {
  }

}
