import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-public-limited-company',
  templateUrl: './public-limited-company.component.html',
  styleUrls: ['./public-limited-company.component.scss']
})
export class PublicLimitedCompanyComponent {

    descriptionTitle = 'Public Limited Company Registration';
    descriptionContent = 'Our Professionals will help you in getting your Company registered under Companies Act, 2013 and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'A Public Limited Company is a company with limited liability towards its shareholders. The public limited company offers shares to the Public and are traded on the stock exchange for the benefit of the company in raising funds. Public Limited Company Stocks can be bought by anyone. A minimum of 3 directors are required and there is no restriction on the maximum number of directors in a public limited company.',
        'In a public limited company, the liability of each shareholder is limited. A minimum paid-up capital of Rs 5 lakh or such higher amount is required and a minimum of 7 shareholders and no restriction on the maximum number of members are required to form a public limited company. Since the shares are sold to the public the risk is spread out and thus, there is a better opportunity for growing and expanding the business by investing in new projects from the money raised through shares.'
    ]
    detailDocumentArray = [
        '2 Photos of each Director',
        'Identity Proof each Director',
        'Proposed Company Name',
        'Office Address Proof',
        'Authorized Capital',
        'PAN copy of each Director',
        'Address Proof of each Director',
        'Rental Agreement or Sales Deed or Lease Deed',
        'Share holder percentage',
        'Paid-up Capital'
    ];
    detailBenefitArray = [
        'A public limited company can have a bulkier magnitude of capital, much more than that gathered by a private limited company.',
        'It is authorized legally to trade on stock exchanges.',
        'No limitation to the maximum number of shareholders in a public limited company.',
        'The public limited company shareholders have limited liabilities, limited roughly to the face value of the shares they own. Also, they do not have to take part in the day-to-day management of the business of the company.',
        'Public limited company shareholders are entitled to transfer their shares freely without needing the consent of someone.'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your private limited registration form'
        },
        {
            item1: 'Obtain DSC & DIN',
            item2: 'DSC and DIN for all directors are required for the documentation of the private company'
        },
        {
            item1: 'Name Approval',
            item2: 'We make company name approval from the respective authority with the recommended document'
        },
        {
            item1: 'Filing MOA & AOA',
            item2: 'We will be filing MOA and AOA for the directors of company'
        }
        ,
        {
            item1: 'Company Certification',
            item2: 'Once the registration completed you will get certificate and CIN number'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: '>What are the primary requirements to form a public limited company?',
            item2: 'In order to set up a public limited company anywhere in India, required a minimum of Seven three Directors and Seven Shareholders. However, the directors can also be shareholders.'
        },
        {
            item1: 'I already have my digital signature certificate and DIN. Will the package value remain the same?',
            item2: 'In case, you already have a DSC and DIN, our experts will offer you some concession accordingly on the above package.'
        },
        {
            item1: 'What is a digital signature certificate (DSC)?',
            item2: 'Digital signature is process to authenticate and validate records electronically. DSC is required for every director of the company as the Ministry of Corporate Affairs (MCA) mandates digital signature of directors on some documents.'
        },
        {
            item1: 'What is Director Identification Number (DIN)?',
            item2: 'DIN is a unique identification number which is allotted to all the directors existing or proposed. DIN can be obtained by filing e-form DIR2 in MCA portal.'
        },
        {
            item1: 'Liabilities of Public limited Company?',
            item2: 'Since a limited company deals with public money, it has to manage rather heavy compliances strictly, which are heavier than those of a private limited company. Other than regular compliances related with tome tax, there are also many other periodic and annual compliances to be made and managed by a limited company with ROC/MCA, SEBI, RBI, etc. These regulatory liabilities are to securing and promoting steadily the profits and welfare of all shareholders of the public limited company.'
        },
        {
            item1: 'Can a NRI be a director of Public limited company?',
            item2: 'Yes, a Foreign National or NRI can be a shareholder or director in a public limited company of India. The main requirement is he should be a sensible adult who such possess the DIN issued by MCA.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 18499,
            charge: 'all inclusive fees',
            features: [
                'Public limited company registration with 7 DSC',
                'DIN',
                'Name approval',
                'Incorporation fee',
                'PAN',
                'TAN',
                'Share certificates',
                'Company kit which include 5 copies of MOA'
            ]
        }
    ]

}
