<div class="service-detail-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="service-detail-tab">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Description">
                            <div class="service-detail-tab-content">
                                <p *ngFor="let serviceDetailDescription of serviceDetailDescriptions">{{ serviceDetailDescription }}</p>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Documents Required">
                            <div class="service-detail-tab-content">
                                <ul class="service-detail-document-list">
                                    <li *ngFor="let serviceDetailDocument of serviceDetailDocuments"><i class='bx bxs-file'></i> {{ serviceDetailDocument }}</li>
                                </ul>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="What We Do ?">
                            <div class="service-detail-tab-content">
                                <ul class="service-detail-whatwedo">
                                    <li *ngFor="let serviceDetailWeDo of serviceDetailWeDos">
                                        <div class="icon">
                                            <i class="flaticon-verify"></i>
                                        </div>
                                        <h3>{{ serviceDetailWeDo.item1 }}</h3>
                                        <p>{{ serviceDetailWeDo.item2 }}</p>
                                    </li>
                                </ul>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Benefits">
                            <div class="service-detail-tab-content">
                                <ul class="service-detail-benefits-list">
                                    <li *ngFor="let serviceDetailBenefit of serviceDetailBenefits"><i class='bx bxl-flutter'></i> {{ serviceDetailBenefit }}</li>
                                </ul>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
