<section class="service-description-area">
    <div class="container">
        <div class="row">
            <div class="col-sm-7">
                <h1>{{ serviceDescriptionTitle }}</h1>
                <h5>{{ serviceDescriptionContent }}</h5>
            </div>
            <div class="col-sm-5">
                <div class="service-description-form">
                    <span class="sub-title">Free consultation</span>
                    <h2><i class='bx bx-phone-call'></i>80 56 56 9875</h2>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Name *">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Phone *">
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Your Email">
                        </div>
                        <button type="submit">Book A Call</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
