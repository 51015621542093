<div class="service-pricing-area">
    <div class="container-fluid">
        <div class="container p-5">
          <div class="row">
            <div class="col-lg-4 col-md-12 mb-4" *ngFor="let servicePricing of servicePricings | slice:0:1" >
                <div class="card h-100 shadow-lg">
                  <div class="card-body">
                    <div class="text-center p-3">
                      <h5 class="card-title">{{ servicePricing.type }}</h5>
                      <br>
                      <span class="h2"><span class="rupee-symbol">₹</span> {{ servicePricing.amount }}</span>
                      <p><small>{{ servicePricing.charge }}</small></p>
                    </div>
                  </div>
                  <div class="card-body text-center">
                      <button class="btn btn-outline-primary btn-lg" style="border-radius:30px">Select</button>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let feature of servicePricing.features">{{ feature }}</li>
                  </ul>
                </div>
            </div>
            <div class="col-lg-8 col-md-12 mb-8">
                <div class="faq-accordion faq-accordion-style-two">
                    <accordion>
                        <accordion-group *ngFor="let serviceFaq of serviceFaqs; let indexOfelement=index;" heading="{{ serviceFaq.item1 }}" [isOpened]="indexOfelement === 0 ? true : false">
                            <p>{{ serviceFaq.item2 }}</p>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
          </div>
        </div>
    </div>
</div>
