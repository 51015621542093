<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2>We Provide Best Quality Services​</h2>
            <p>Arama Management encourages you to start and grow your own business. We help private and corporate business by providing efficient and cost effective service on tax planning, licensing, registration and loans.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="home-service-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>GST</h3>
                    <p>GST is an indirect tax imposed on the supply of goods and services sold domestically for consumption.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="home-service-box">
                    <div class="icon">
                        <i class="flaticon-anywhere"></i>
                    </div>
                    <h3>Private Limited Company</h3>
                    <p>A private limited company is a company which is owned by non-governmental organisations or shareholders.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="home-service-box">
                    <div class="icon">
                        <i class="flaticon-graduated"></i>
                    </div>
                    <h3>LLP</h3>
                    <p>LLP gives the benefits of limited liability of a company and the flexibility of a partnership.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="home-service-box">
                    <div class="icon">
                        <i class="flaticon-self-improvement"></i>
                    </div>
                    <h3>FSSAI</h3>
                    <p>FSSAI Registration is a basic registration of food business in the central department of FSSAI.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="home-service-box">
                    <div class="icon">
                        <i class="flaticon-padlock"></i>
                    </div>
                    <h3>Trademark</h3>
                    <p>Trademark is any  unique symbolic expression used to represent a product or service.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="home-service-box">
                    <div class="icon">
                        <i class="flaticon-launch"></i>
                    </div>
                    <h3>Importer-Exporter Code</h3>
                    <p>An Importer-Exporter Code (IEC) is a key business identification number which is mandatory for Exports and Imports.</p>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="home-service-btn">
                    <a routerLink="/" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Get Started Today</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="divider bg-e4feff"></div>
</section>
