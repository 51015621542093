import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-trade-license-registration',
  templateUrl: './trade-license-registration.component.html',
  styleUrls: ['./trade-license-registration.component.scss']
})
export class TradeLicenseRegistrationComponent {

    descriptionTitle = 'Trade License Registration';
    descriptionContent = 'Our Professionals will help you in getting your Trade License and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Trade License can be obtained by an individual to commence a new business.It is a certificate that grants  permission to run particular business for which it is issued. Trade licenses is mandatory for these business: hotels, restaurants, canteen, food stall, bakeries, sale of vegetables, meat, provisions store, etc. manufacturing industries, factories, power looms, flour mills, cyber café, etc. dhobi shop,barber shop, timber wood, sale of fire wood, candle manufacturer, cracker manufacturer, etc.'
    ]
    detailDocumentArray = [
        'Pan Card of the establishment in case of company, LLP or Firm',
        'Certificate of Incorporation, MOA and AOA of the company or LLP/ Partnership Agreement as the case may be',
        'Colour Photograph, Pan card and ID Proof and Address Proof of all Directors/ Partners',
        'Site/Key Plan Showing the area under the occupation of the applicant earmarking the neighborhood of the site',
        'Canceled Cheque and bank statement of the establishment',
        'Premises Proof of the establishment in the form of Sale Deed, Electricity Bill/water bill and NOC from owner',
        'Front-Facia Photograph of the establishment with Display of goods traded from the premises'
    ];
    detailBenefitArray = [
        'Increase Credibility',
        'Ensure fewer confrontation with legal affairs',
        'Ensure better sanitation within the organization',
        'Increase Goodwill in the target market'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your Trade License registration form'
        },
        {
            item1: 'Gathering Documents',
            item2: 'We would be collecting all the necessary documents from you for the registration'
        },
        {
            item1: 'Submitting Application',
            item2: 'With appropriate documents we will submit documents to respective department'
        },
        {
            item1: 'Certificate Issue',
            item2: 'Registration certificate will be issued after verification from respective department'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'How long it takes to complete the Trade licence registration process?',
            item2: 'If the documents are without error it takes 8 days or else more.'
        },
        {
            item1: 'What to do if Trade licence got lapsed?',
            item2: 'The renewal form has to be filed with the fees.'
        },
        {
            item1: 'Whom should I contact to clear the inquiries regarding my Trade licence?',
            item2: 'Zonal Chief Officer and  Health Inspector of concerned ward should be contacted.'
        },
        {
            item1: 'What is the period of Renewal of Trade Licence?',
            item2: '1st of Jan to 31st of March is the Renewal period every year.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 3999,
            charge: 'all exclusive fees',
            features: [
                'Trade License Certificate'
            ]
        }
    ]

}
