<header class="header-area p-relative">
    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8 header-info">
                    <div class="top-header-social">
                        <a href="tel:8056569875"><i class='bx bx-phone-call'></i></a>
                        <a href="tel:8056569875">+91 80 56 569875</a>
                    </div>
                    <div class="top-header-social">
                      <a><i class='bx bx-time space'></i></a>
                      <a>Monday - Saturday: 8:00 - 19:00</a>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul class="top-header-login-register">
                        <li><a routerLink="/offer"><i class='bx bxs-offer'></i>Offers</a></li>
                        <li><a routerLink="/eventtest"><i class='bx bxs-calendar'></i>Events</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area navbar-style-three" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/arama/general/arama-logo-header.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Startup<i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu menu-1">
                                <div class="container">
                                    <div class="row">
                                      <div class="col-lg-12 col-xl-12">
                                        <div class="position">
                                          <div class="row">
                                            <div class="col-lg-6 mb-6">
                                              <h6 class="font-weight-bold text-uppercase">Company & Firm Registrations</h6>
                                              <ul class="list-unstyled">
                                                <li class="nav-item"><a routerLink="/privatelimitedcompany" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Private Limited Company</a></li>
                                                <li class="nav-item"><a routerLink="/onepersoncompany" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">One Person Company (OPC)</a></li>
                                                <li class="nav-item"><a routerLink="/publiclimitedcompany" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Public Limited Company</a></li>
                                                <li class="nav-item"><a routerLink="/nidhicompany" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Nidhi Company</a></li>
                                                <li class="nav-item"><a routerLink="/section8company" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Section 8 Company</a></li>
                                                <li class="nav-item"><a routerLink="/producercompany" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Producer Company</a></li>
                                                <li class="nav-item"><a routerLink="/foreignsubsidiarycompany" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Foreign Subsidiary Company</a></li>
                                                <li class="nav-item"><a routerLink="/limitedliabilitypartnership" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Limited Liability Partnership (LLP)</a></li>
                                                <li class="nav-item"><a routerLink="/partnershipfirm" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Partnership Firm</a></li>
                                                <li class="nav-item"><a routerLink="/soleproprietor" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Sole Proprietor</a></li>
                                              </ul>
                                            </div>
                                            <div class="col-lg-6 mb-6">
                                              <h6 class="font-weight-bold text-uppercase">Business Registrations</h6>
                                              <ul class="list-unstyled">
                                                <li class="nav-item"><a routerLink="/professionaltaxregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Professional Tax Registration</a></li>
                                                <li class="nav-item"><a routerLink="/shopestablishment" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Shop & Establishment</a></li>
                                                <li class="nav-item"><a routerLink="/importexportcode" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Import & Export Code</a></li>
                                                <li class="nav-item"><a routerLink="/fssailicense" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">FSSAI License</a></li>
                                                <li class="nav-item"><a routerLink="/firelicense" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Fire License</a></li>
                                                <li class="nav-item"><a routerLink="/factoryLicense" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Factory License</a></li>
                                                <li class="nav-item"><a routerLink="/esiregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">ESI Registration</a></li>
                                                <li class="nav-item"><a routerLink="/pfregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">PF Registration</a></li>
                                                <li class="nav-item"><a routerLink="/msmeregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">MSME Registration</a></li>
                                                <li class="nav-item"><a routerLink="/isoregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">ISO Registration</a></li>
                                                <li class="nav-item"><a routerLink="/adcoderegistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">AD Code Registration</a></li>
                                                <li class="nav-item"><a routerLink="/tradelicenseregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Trade License Registration</a></li>
                                                <li class="nav-item"><a routerLink="/druglicense" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Drug License</a></li>
                                                <li class="nav-item"><a routerLink="/digitalsignaturecertificate" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Digital Signature Certificate (DSC)</a></li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">GST<i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu menu-2">
                                <div class="container">
                                    <div class="row">
                                      <div class="col-lg-12 col-xl-12">
                                        <div class="position">
                                          <div class="row">
                                            <div class="col-lg-12 mb-12">
                                              <ul class="list-unstyled">
                                                <li class="nav-item"><a routerLink="/gstregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">GST Registration</a></li>
                                                <li class="nav-item"><a routerLink="/gstlutfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">GST LUT Filing</a></li>
                                                <li class="nav-item"><a routerLink="/gstcancellation" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">GST Cancellation</a></li>
                                                <li class="nav-item"><a routerLink="/gstreturnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">GST Return Filing</a></li>
                                                <li class="nav-item"><a routerLink="/gstannualreturnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">GST Annual Return Filing</a></li>
                                                <li class="nav-item"><a routerLink="/gstappeal" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">GST Appeal</a></li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Income Tax<i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu menu-3">
                                <div class="container">
                                    <div class="row">
                                      <div class="col-lg-12 col-xl-12">
                                        <div class="position">
                                          <div class="row">
                                            <div class="col-lg-4 mb-4">
                                              <ul class="list-unstyled">
                                                <li class="nav-item"><a routerLink="/itr1returnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">ITR -1 Return Filing</a></li>
                                                <li class="nav-item"><a routerLink="/itr2returnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">ITR -2 Return Filing</a></li>
                                                <li class="nav-item"><a routerLink="/itr3returnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">ITR -3 Return Filing</a></li>
                                                <li class="nav-item"><a routerLink="/itr4returnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">ITR -4 Return Filing</a></li>
                                              </ul>
                                            </div>
                                            <div class="col-lg-4 mb-4">
                                                <ul class="list-unstyled">
                                                  <li class="nav-item"><a routerLink="/itr5returnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">ITR -5 Return Filing</a></li>
                                                  <li class="nav-item"><a routerLink="/itr6returnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">ITR -6 Return Filing</a></li>
                                                  <li class="nav-item"><a routerLink="/itr7returnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">ITR -7 Return Filing</a></li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-4 mb-4">
                                                <ul class="list-unstyled">
                                                  <li class="nav-item"><a routerLink="/tdsreturnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">TDS Return Filing</a></li>
                                                  <li class="nav-item"><a routerLink="/pfreturnfiling" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">PF Return Filing</a></li>
                                                </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Compliances<i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu menu-4">
                                <div class="container">
                                    <div class="row">
                                      <div class="col-lg-12 col-xl-12">
                                        <div class="position">
                                          <div class="row">
                                            <div class="col-lg-6 mb-6">
                                                <h6 class="font-weight-bold text-uppercase">Business Compliances</h6>
                                              <ul class="list-unstyled">
                                                <li class="nav-item"><a routerLink="/namechange" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Name Change</a></li>
                                                <li class="nav-item"><a routerLink="/addresschange" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Address Change</a></li>
                                                <li class="nav-item"><a routerLink="/adddirector" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Add Director</a></li>
                                                <li class="nav-item"><a routerLink="/removedirector" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Remove Director</a></li>
                                                <li class="nav-item"><a routerLink="/increaseauthorizedcapital" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Increase Authorized Capital</a></li>
                                                <li class="nav-item"><a routerLink="/sharetransfer" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Share Transfer</a></li>
                                                <li class="nav-item"><a routerLink="/moaamendment" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">MOA Amendment</a></li>
                                                <li class="nav-item"><a routerLink="/appointmentofauditor" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Appointment Of Auditor</a></li>
                                                <li class="nav-item"><a routerLink="/removalofauditor" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Removal Of Auditor</a></li>
                                                <li class="nav-item"><a routerLink="/windingupcompany" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Winding Up Company</a></li>
                                              </ul>
                                            </div>
                                            <div class="col-lg-6 mb-6">
                                                <h6 class="font-weight-bold text-uppercase">Annual Compliances</h6>
                                                <ul class="list-unstyled">
                                                  <li class="nav-item"><a routerLink="/privatelimitedcompanycompliance" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Private Limited Company</a></li>
                                                  <li class="nav-item"><a routerLink="/limitedliabilitycompanycompliance" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Limited Liability Company</a></li>
                                                  <li class="nav-item"><a routerLink="/partnershipcompliance" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Partnership</a></li>
                                                  <li class="nav-item"><a routerLink="/proprietorcompliance" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Proprietor</a></li>
                                                </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Business Protection<i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu menu-5">
                                <div class="container">
                                    <div class="row">
                                      <div class="col-lg-12 col-xl-12">
                                        <div class="position">
                                          <div class="row">
                                            <div class="col-lg-6 mb-6">
                                              <h6 class="font-weight-bold text-uppercase">Intellectual Property</h6>
                                              <ul class="list-unstyled">
                                                <li class="nav-item"><a routerLink="/trademarkregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Trademark Registration</a></li>
                                                <li class="nav-item"><a routerLink="/trademarkrenewal" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Trademark Renewal</a></li>
                                                <li class="nav-item"><a routerLink="/trademarkobjection" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Trademark Objection</a></li>
                                                <li class="nav-item"><a routerLink="/trademarkrectification" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Trademark Rectification</a></li>
                                                <li class="nav-item"><a routerLink="/trademarkassignment" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Trademark Assignment</a></li>
                                              </ul>
                                            </div>
                                            <div class="col-lg-6 mb-6">
                                              <h6 class="font-weight-bold text-uppercase header-visible">A</h6>
                                              <ul class="list-unstyled">
                                                <li class="nav-item"><a routerLink="/copyrightregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Copyright Registration</a></li>
                                                <li class="nav-item"><a routerLink="/designregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Design Registration</a></li>
                                                <li class="nav-item"><a routerLink="/patentregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Patent Registration</a></li>
                                                <li class="nav-item"><a routerLink="/intellectualpropertydispute" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Intellectual Property Dispute</a></li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Other Registrations<i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu menu-2">
                                <div class="container">
                                    <div class="row">
                                      <div class="col-lg-12 col-xl-12">
                                        <div class="position">
                                          <div class="row">
                                            <div class="col-lg-12 mb-12">
                                              <ul class="list-unstyled">
                                                <li class="nav-item"><a routerLink="/trustregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Trust Registration</a></li>
                                                <li class="nav-item"><a routerLink="/ngoregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">NGO Registration</a></li>
                                                <li class="nav-item"><a routerLink="/societyregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Society Registration</a></li>
                                                <li class="nav-item"><a routerLink="/section8registration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">Section 8 Registration</a></li>
                                                <li class="nav-item"><a routerLink="/fcraregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">FCRA Registration</a></li>
                                                <li class="nav-item"><a routerLink="/80g12aregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">80G & 12A Registration</a></li>
                                                <li class="nav-item"><a routerLink="/12aaregistration" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link text-small pb-0">12AA Registration</a></li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">More <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">E-Learnings</a></li>
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Videos</a></li>
                                <li class="nav-item"><a routerLink="/payment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Payment</a></li>
                                <li class="nav-item"><a routerLink="/service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item"><a routerLink="/contacttest" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>
