import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-sole-proprietor',
  templateUrl: './sole-proprietor.component.html',
  styleUrls: ['./sole-proprietor.component.scss']
})
export class SoleProprietorComponent {

    descriptionTitle = 'Sole Proprietor Registration';
    descriptionContent = 'Our Professionals will help you in getting your Company registered under Companies Act, 2013 and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'The sole proprietorship is the simplest form of business that legally has no separate existence from its owner. a proprietorship is an unregistered business entity. It simply means a person who owns and manages the business is personally responsible for its debts. A sole proprietorship is a popular form of business in India. It can either be operated under the name of its owner itself or by using a trade name that does not create a legal entity separate from its owner.',
        'The sole proprietorship is common among small businessmen since no complexity involved in setting up of business and can be started at a nominal cost. It also has minimal registration formalities for setting up as well as no formality for winding up or closing the proprietorship A sole proprietor also has an advantage of earning all its profits. Sole proprietorships often have their bank accounts in the name of their owner. However, in order to open the bank accounts in the name of their business, they may have to register their business in GST or any other licensing documents may require.'
    ]
    detailDocumentArray = [
        '2 Photos of the proprietor',
        'Identity Proof',
        'Office Address Proof',
        'Bank Account',
        'PAN copy of the Proprietor',
        'Address Proof',
        'Rental Agreement or Sales Deed or Lease Deed'
    ];
    detailBenefitArray = [
        'A sole proprietor has full control over decision-making in the business',
        'Sale or transfer can take place at the discretion of the sole proprietor',
        'No corporate tax payments',
        'Minimal legal costs to forming a sole proprietorship',
        'Few formal business requirements'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'GST Registration',
            item2: 'Our Professionals will collect the required documents from you and register your business with MSME'
        },
        {
            item1: 'MSME Registration',
            item2: 'Our Professionals will collect the required documents from you and register your business with MSME'
        },
        {
            item1: 'Chartered Accountant Certificate',
            item2: 'Our Chartered Accountant will verify all the documents and provide a certificate to open the Current Account in Bank'
        },
        {
            item1: 'Shop Act Licence',
            item2: 'Our Professionals will help you in getting your Licenses'
        },
        {
            item1: 'Business ITR',
            item2: 'Our Professionals will file your ITR forms'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'What will be the title for a Sole Proprietor Firm?',
            item2: 'It usually go with the name of the owner or according to his decision.'
        },
        {
            item1: 'Is it possible for a Sole Proprietor to open a Current Account in the name of his Business?',
            item2: 'Yes, It is possible for a sole proprietor to open a current account in the name of his business, if he had registered his business.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 1399,
            charge: 'all inclusive fees',
            features: [
                'GST',
                'MSME Registration'
            ]
        }
    ]

}
