import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-import-export-code',
  templateUrl: './import-export-code.component.html',
  styleUrls: ['./import-export-code.component.scss']
})
export class ImportExportCodeComponent {

    descriptionTitle = 'Import Export Code Registration';
    descriptionContent = 'Our Professionals will help you in getting your Import Export Code and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'IE Code registration is mandatory for persons who are exporting or importing goods from India. It is issued by the Directorate General of Foreign Trade (DGFT) . All entities engaged in Import and Export of goods require the 10 digit Import Export Code. IE code has not to be renewed, it has lifetime validity.  Exporters can’t enjoy any benefit of exports from DGFT, customs, Export Promotion Council and Importers are not allowed to proceed, without IE code.'
    ]
    detailDocumentArray = [
        'Identity Proof',
        'Passport-size photograph',
        'PAN Card',
        'Cancelled Cheque',
        'Business Proof'
    ];
    detailBenefitArray = [
        'Exports from DGFT',
        'Export Promotion Council and Importers are not allowed to proceed, without IE code'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your import export code form (ANF2A form)'
        },
        {
            item1: 'Gathering Documents',
            item2: 'Gathering the necessary documents for the ANF2A form and filling the details'
        },
        {
            item1: 'IEC Approval',
            item2: 'After gathering all the documents forwarding collected details to IEC department'
        },
        {
            item1: 'IE Code',
            item2: 'IE code would be generating after the successful verification of details'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'Does PAN number is must for IE code?',
            item2: 'Yes, PAN card details is mandatory.'
        },
        {
            item1: 'How long does it takes to process IE code?',
            item2: 'Will be processed and disposed within two working days.IEC Certificates will be issued in digital format.'
        },
        {
            item1: 'Does IE code used by Customs department?',
            item2: 'Yes, customs generate BIN (Business Identification Number) based on IEC and PAN.'
        },
        {
            item1: 'Can we check IEC status online?',
            item2: 'Yes, click on http://dgft.gov.in/iecstatus to know the status at customs'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 1999,
            charge: 'all inclusive fees',
            features: [
                'IE Code Certificate'
            ]
        }
    ]

}
