<div class="banner-section">
    <div class="d-table">
            <div class="container">
                <div class="banner-content">
                    <h1>Manage your business more efficiently</h1>
                    <p>We help you to start and grow your own business</p>
                    <div class="btn-box">
                        <a class="video-btn">ARAMA<span></span></a>
                    </div>
                </div>
        </div>
    </div>
    <div class="divider"></div>
</div>
