import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-patent-registration',
  templateUrl: './patent-registration.component.html',
  styleUrls: ['./patent-registration.component.scss']
})
export class PatentRegistrationComponent {

    descriptionTitle = 'Patent Registration';
    descriptionContent = 'Our Professionals will help you in getting your Patent Registration and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'A Patent registration helps you to get a patent of an intellectual property right to an invention carried out by an individual or firm. The Indian government has initiated the IP Department to grant you the full right to register your invention under patent (but only if it is unique). The patent is granted to the owner of the invention that prevents others from making, using, importing or selling the invention without his authorization. The inventor must submit the required proof of the invention as asked by the Government. Those patent registered will be valid for a period of 20 years from the date of filing of the application for the patent. Patent registration does not long for a lifetime.'
    ]
    detailDocumentArray = [
        'RandomDocument1',
        'RandomDocument2',
        'RandomDocument3',
        'RandomDocument4'
    ];
    detailBenefitArray = [
        'RandomBenefit1',
        'RandomBenefit2',
        'RandomBenefit3',
        'RandomBenefit4'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'RandomDetail1',
            item2: 'RandomDetailDescription1'
        },
        {
            item1: 'RandomDetail2',
            item2: 'RandomDetailDescription2'
        },
        {
            item1: 'RandomDetail3',
            item2: 'RandomDetailDescription3'
        },
        {
            item1: 'RandomDetail4',
            item2: 'RandomDetailDescription4'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'RandomFaq1',
            item2: 'RandomFaqDescrition1'
        },
        {
            item1: 'RandomFaq2',
            item2: 'RandomFaqDescrition2'
        },
        {
            item1: 'RandomFaq3',
            item2: 'RandomFaqDescrition3'
        },
        {
            item1: 'RandomFaq4',
            item2: 'RandomFaqDescrition4'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'BASIC',
            amount: 1000,
            charge: 'all inclusive fees',
            features: [
                'RandomText1',
                'RandomText2',
                'RandomText3'
            ]
        },
        {
            type: 'PREMIUM',
            amount: 2000,
            charge: 'all inclusive fees',
            features: [
                'RandomText1',
                'RandomText2',
                'RandomText3'
            ]
        },
        {
            type: 'SUPREME',
            amount: 3000,
            charge: 'all inclusive fees',
            features: [
                'RandomText1',
                'RandomText2',
                'RandomText3',
                'RandomText1',
                'RandomText2',
                'RandomText3',
                'RandomText1',
                'RandomText2',
                'RandomText3',
                'RandomText1',
                'RandomText2',
                'RandomText3'
            ]
        }
    ]

}
