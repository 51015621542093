import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-producer-company',
  templateUrl: './producer-company.component.html',
  styleUrls: ['./producer-company.component.scss']
})
export class ProducerCompanyComponent {

    descriptionTitle = 'Producer Company Registration';
    descriptionContent = 'Our Professionals will help you in getting your Company registered under Companies Act, 2013 and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'A producer company is a legal entity registered under Company Act,2013. Produce includes the products yield through farming. A Producer company can follow the objectives of the business under production, harvesting, procurement, grading, pooling, handling, marketing, selling, and export of primary produce of the Members or import of goods or services for their benefit.',
        'The Producer company is a separate legal Entity which can be formed by ten or more individuals or two or more institutions dealing with agricultural production or post-harvesting processes. The main advantage of a producer company is it enjoys a separate legal entity and offers a limited liability and perpetuity. It also offers greater creditability than an unregistered organization of farmers. The Producer company also if fully entitled to accept deposits from or give loans to its agricultural members.'
    ]
    detailDocumentArray = [
        '2 Photos of each Director',
        'Identity Proof each Director',
        'Proposed Company Name',
        'Office Address Proof',
        'Authorized Capital',
        'PAN copy of each Director',
        'Address Proof of each Director',
        'Rental Agreement or Sales Deed or Lease Deed',
        'Share holder percentage',
        'Paid-up Capital'
    ];
    detailBenefitArray = [
        'The producer member against security, repayable within a period not exceeding seven years from the date of disbursement of such loans or advances.',
        'NABARD provides support and financial assistance to meet the needs of Producer Companies. In 2011, NABARD set up a Rs. 50 crore Producer Organisation Development Fund (PODF), out of its operating surplus.'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your private limited registration form'
        },
        {
            item1: 'Obtain DSC & DIN',
            item2: 'DSC and DIN for all directors are required for the documentation of the private company'
        },
        {
            item1: 'Name Approval',
            item2: 'We make company name approval from the respective authority with the recommended document'
        },
        {
            item1: 'Filing MOA & AOA',
            item2: 'We will be filing MOA and AOA for the directors of company'
        },
        {
            item1: 'Company Certification',
            item2: 'Once the registration completed you will get certificate and CIN number'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'Loans and Investments',
            item2: 'The Producer Company consist of individuals who are primary producers, and thus, are in need of financial support from time to time. Hence, a special provision under the companies acts 1956 was passed for giving loans to producer members. A Producer Company can provide financial assistance to its members.'
        },
        {
            item1: 'What is Farmer Producer Company?',
            item2: 'A Farmer Producer Company is a hybrid between cooperative societies and private limited companies. The objective of the concept of FPC is to organize farmers into a collective to improve their bargaining strength in the market.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 18499,
            charge: 'all inclusive fees',
            features: [
                'Producer company registration with 2 DSC and DIN',
                'Incorporation fee',
                'PAN',
                'TAN',
                'Name approval',
                'Share certificates',
                'Company kit which include 5 copies of MOA'
            ]
        }
    ]

}
