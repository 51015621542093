<div class="shop-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a>
                            <img src="assets/img/arama/offer/offer-1.jpg" alt="image">
                            <img src="assets/img/arama/offer/offer-1.jpg" alt="image">
                        </a>
                        <div class="sale-btn">Sale!</div>
                    </div>
                    <div class="product-content">
                        <h3>Private Limited Registration</h3>
                        <div class="price">
                            <span class="old"><span class="rupee-symbol">₹</span> 9999</span>
                            <span class="new"><span class="rupee-symbol">₹</span> 6999</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a>
                            <img src="assets/img/arama/offer/offer-2.jpg" alt="image">
                            <img src="assets/img/arama/offer/offer-2.jpg" alt="image">
                        </a>
                        <div class="sale-btn">Sale!</div>
                    </div>
                    <div class="product-content">
                        <h3>GST Registration</h3>
                        <div class="price">
                            <span class="old"><span class="rupee-symbol">₹</span> 999</span>
                            <span class="new"><span class="rupee-symbol">₹</span> 499</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a>
                            <img src="assets/img/arama/offer/offer-3.jpg" alt="image">
                            <img src="assets/img/arama/offer/offer-3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h3>Tax Salary Return Filing</h3>
                        <div class="price">
                            <span class="old"><span class="rupee-symbol">₹</span> 999</span>
                            <span class="new"><span class="rupee-symbol">₹</span> 499</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a>
                            <img src="assets/img/arama/offer/offer-4.jpg" alt="image">
                            <img src="assets/img/arama/offer/offer-4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h3>Income Tax Return Filing for Business Only</h3>
                        <div class="price">
                            <span class="old"><span class="rupee-symbol">₹</span> 2999</span>
                            <span class="new"><span class="rupee-symbol">₹</span> 1999</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a>
                            <img src="assets/img/arama/offer/offer-5.jpg" alt="image">
                            <img src="assets/img/arama/offer/offer-5.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h3>Income Tax Return Filing for Firm Only</h3>
                        <div class="price">
                            <span class="old"><span class="rupee-symbol">₹</span> 3499</span>
                            <span class="new"><span class="rupee-symbol">₹</span> 2499</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-product-box mb-30">
                    <div class="product-image">
                        <a>
                            <img src="assets/img/arama/offer/offer-6.jpg" alt="image">
                            <img src="assets/img/arama/offer/offer-6.jpg" alt="image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h3>Income Tax Return Filing for Private Limited Companies Only</h3>
                        <div class="price">
                            <span class="old"><span class="rupee-symbol">₹</span> 5999</span>
                            <span class="new"><span class="rupee-symbol">₹</span> 2999</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
