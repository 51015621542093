import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-trademark-registration',
  templateUrl: './trademark-registration.component.html',
  styleUrls: ['./trademark-registration.component.scss']
})
export class TrademarkRegistrationComponent {

    descriptionTitle = 'Trademark Registration';
    descriptionContent = 'Our Professionals will help you in getting your Trademark Registration and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Trademark is any symbolic expression related to a product or service that differentiate it from others. This could be expressed as a word, photograph, slogan, logo, colour,graphic, combination, sound or even smell. However, it is an intellectual property for an entity and an intangible asset and most entities are only looking for a brand name registration or logo registration it is to protect the company’s investment in the brand or symbol.',
        'Trademarks Registrations are registered under the Trademark Act, 1999 and registered by the Controller General of Patents Designs and Trademarks, Ministry of Commerce and Industry, Government of India.  Trademark enables the trademark owner the right to sue when violation of trademarks occur.'
    ]
    detailDocumentArray = [
        'Identity Proof',
        'Trademark Copy',
        'Proof of Business',
        'Trademark Register Detail',
        'Attorney Document'
    ];
    detailBenefitArray = [
        'Trademark makes you to have exclusive control over it',
        'Trademark build confidence and recognition of product or service among customers in the market',
        'Trademark protect designated as intellectual property, which means it is protected against infringement'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your trademark registration form'
        },
        {
            item1: 'Trademark Search',
            item2: 'Searching for the trademark name or logo is similar to other trademark registered'
        },
        {
            item1: 'Applying Trademark',
            item2: 'After successful unique verification we will apply for trademark registration'
        },
        {
            item1: 'Filing Trademark',
            item2: 'Filing the trademark after the approval from the respective department'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'What is Trademark Rectification?',
            item2: 'While Processing the trademark registration process, an application can be marked as formalities chk fail by the Trademark Examiner, in such cases the applicant can go for Trademark Rectification.'
        },
        {
            item1: 'What is Trademark Objection?',
            item2: 'While processing the trademark registration process, an objection can be raised by the Trademark Examiner and in such cases, the applicant can write reply for the objection raised and submit.'
        },
        {
            item1: 'What is Trademark Opposition?',
            item2: 'While processing the trademark registration process, an opposition can be raised by any person in the public for registration of the trademark when it it set for the advertisement in Trademark journal before the registration, In such cases the trademark applicant has to file a counter within 2 months with the Trademark Registrar.'
        },
        {
            item1: 'How long it takes to register Trademark?',
            item2: 'The application to the Trademark registration could be done in few hours. It may take 18 to 24 months , to get confirmation and to get confirmed registration certificate from the government. One you get you can use the ® symbol.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 1999,
            charge: 'all inclusive fees',
            features: [
                'Registered Trademark',
            ]
        }
    ]


}
