import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-one-person-company',
  templateUrl: './one-person-company.component.html',
  styleUrls: ['./one-person-company.component.scss']
})
export class OnePersonCompanyComponent {

    descriptionTitle = 'One Person Company Registration';
    descriptionContent = 'Our Professionals will help you in getting your Company registered under Companies Act, 2013 and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'One Person company is a revolutionary concept introduced by the Companies Act,2013. The One Person Company (OPC) is a sort of privately-owned business having just a single member. The OPC is incorporated like a Private Limited Company as a separate legal entity from its promoters, where there is only one member and an invitation to the public for subscription of the securities of the company is prohibited. As an OPC is a separate legal entity distinct from its promoter, it has its own assets and liabilities. Thus, the promoter is not personally liable for the debts of the company.',
        'OPC might be shaped either as a company constrained or limited by shares or an unlimited liability company. The OPCs, unlike any other companies, must mention a nominee with the nominee’s written consent while filing with Registrar of companies.  This nominee shall become a member in case the sole director is disabled. A characteristic individual should not be an individual for more than One OPC and the said individual might not be a nominee of more than one OPC. If the OPC is willing to add Shareholder all it needs to do is an alteration of memorandum of association and file, it before ROC.'
    ]
    detailDocumentArray = [
        '2 Photos of each Director',
        'Identity Proof each Director',
        'Proposed Company Name',
        'Office Address Proof',
        'Authorized Capital',
        'PAN copy of each Director',
        'Address Proof of each Director',
        'Rental Agreement or Sales Deed or Lease Deed',
        'Share holder percentage',
        'Paid-up Capital'
    ];
    detailBenefitArray = [
        'Only one Director is needed to form an OPC',
        'Section 173 which states a limited company to conduct at least four Board meetings every year, is not applicable for OPCs.',
        'The provisions and regulations as per Section 98 and Sections from 100 to 111, related to the general meetings, are also not applicable to OPCs.',
        'An OPC also has exceptions from many other legal, governance, and regulatory compliances.',
        'The rotation of auditor after every five-year period is also not applicable to an OPC.'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your private limited registration form'
        },
        {
            item1: 'Obtain DSC & DIN',
            item2: 'DSC and DIN for all directors are required for the documentation of the private company'
        },
        {
            item1: 'Name Approval',
            item2: 'We make company name approval from the respective authority with the recommended document'
        },
        {
            item1: 'Filing MOA & AOA',
            item2: 'We will be filing MOA and AOA for the directors of company'
        }
        ,
        {
            item1: 'Company Certification',
            item2: 'Once the registration completed you will get certificate and CIN number'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'Conditions for an OPC to covert to a Private or Public limited cpmpany?',
            item2: 'Companies (Incorporation) Rules, 2014, states that a One Person Company must change itself into a private limited company or a public limited company, if at any point of time, its paid-up capital exceeds INR 50 Lac or its average annual turnover of three immediately preceding consecutive financial years becomes more than INR 2 Crore. Under any of these conditions, the OPC is necessarily required to inform the relevant ROC through Form INC-5, within 60 Days of the exceeding threshold limits. Here, it may also be noted that an OPC cannot voluntarily change itself into any type of company, within two years of its incorporation, except under any of these two cases of exceeding the threshold limits.'
        },
        {
            item1: 'Does Foreign Direct Investment id allowed for OPC in India?',
            item2: 'No, Foreign Direct Investment into a one-person company in India is restricted.'
        },
        {
            item1: 'Can a NRI be a Director in OPC?',
            item2: 'No, no other persons other than an Indian citizen or a Resident in India can register in a one person company.'
        },
        {
            item1: 'What are the requirements to be a director?',
            item2: 'Any person above 21 years can become a director. Non-residents can also become director of Indian companies.'
        },
        {
            item1: 'I already have my digital signature certificate and DIN. Will the package value remain the same?',
            item2: 'In case, you already have a DSC and DIN, our experts will offer you some concession accordingly on the above package.'
        },
        {
            item1: 'What is a digital signature certificate (DSC)?',
            item2: 'Digital signature is process to authenticate and validate records electronically. DSC is required for every director of the company as the Ministry of Corporate Affairs (MCA) mandates digital signature of directors on some documents.'
        },
        {
            item1: 'What is Director Identification Number (DIN)?',
            item2: 'DIN is a unique identification number which is allotted to all the directors existing or proposed. DIN can be obtained by filing e-form DIR2 in MCA portal.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'BASIC',
            amount: 4499,
            charge: 'all inclusive fees',
            features: [
                'One Person company registration with 1 DSC',
                'DIN',
                'Incorporation fee',
                'MOA & AOA'
            ]
        },
        {
            type: 'PREMIUM',
            amount: 5499,
            charge: 'all inclusive fees',
            features: [
                'One Person company registration with 1 DSC',
                'DIN',
                'Incorporation fee',
                'MOA & AOA',
                'PAN',
                'TAN',
                'Name approval'
            ]
        },
        {
            type: 'SUPREME',
            amount: 9499,
            charge: 'all inclusive fees',
            features: [
                'One Person company registration with 1 DSC',
                'DIN',
                'Incorporation fee',
                'MOA & AOA',
                'PAN',
                'TAN',
                'Name approval',
                'Decleration Of commencement',
                'Auditor appointment'
            ]
        }
    ]
}
