<div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg1">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3>MGT 7/7A Filing for Companies & OPC</h3>
                        <p>Every company shall file its annual return in Form No. MGT-7 except One Person Company (OPC) and Small Company.</p>
                        <span class="location"><i class='bx bx-map'></i> Chennai</span>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>Tue, 29</span>
                                <h3>November, 2022</h3>
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg2">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3>Revised Income Tax filing for AY 2022-2023</h3>
                        <p>In a revised return, the taxpayer can disclose any additional income missed in the original return.</p>
                        <span class="location"><i class='bx bx-map'></i> Chennai</span>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>Sat, 31</span>
                                <h3>December, 2022</h3>
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg3">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3>GST Annual Return filings</h3>
                        <p>GST annual return filing is mandatory for all entities with a turnover of more than two crores required to obtain GST registration.</p>
                        <span class="location"><i class='bx bx-map'></i> Chennai</span>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>sat, 31</span>
                                <h3>December, 2022</h3>
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="single-events-box mb-30">
            <div class="events-box">
                <div class="events-image">
                    <div class="image bg4">
                    </div>
                </div>
                <div class="events-content">
                    <div class="content">
                        <h3>Budget 2023 Impact Review</h3>
                        <p>The Budget 2023 is challenging in many ways. This is a time for fiscal consolidation.</p>
                        <span class="location"><i class='bx bx-map'></i> Chennai</span>
                    </div>
                </div>
                <div class="events-date">
                    <div class="date">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <span>Thu, 2</span>
                                <h3>February, 2023</h3>
                                <i class='bx bx-calendar'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
