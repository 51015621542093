<div class="service-highlight-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="service-highlight-boxes-item">
                    <h3 class="boxes-btn">On Time<i class='bx bx-plus'></i></h3>
                    <p>Well managed and continuously improved can reduce errors, shorten process completion times</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="service-highlight-boxes-item bg-image">
                    <h3 class="boxes-btn">Support<i class='bx bx-plus'></i></h3>
                    <p>Helping you in business related queries which are necessary for smooth running of business.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="service-highlight-boxes-item bg-color">
                    <h3 class="boxes-btn">Skilled<i class='bx bx-plus'></i></h3>
                    <p>Experts are in our team, and this means improved productivity for your business.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="service-highlight-boxes-item">
                    <h3 class="boxes-btn">Leading<i class='bx bx-plus'></i></h3>
                    <p>One of the leading company in India, and extremely efficient in offering business support.</p>
                </div>
            </div>
        </div>
    </div>
</div>
