import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-phonenumber',
  templateUrl: './service-phonenumber.component.html',
  styleUrls: ['./service-phonenumber.component.scss']
})
export class ServicePhonenumberComponent implements OnInit {
  phoneNumberArray = [
    '8056569875',
    '9840817970',
    '8489577005']
  constructor() { }

  ngOnInit(): void {
  }

}
