<div class="service-phonenumber-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4" *ngFor="let phoneNumber of phoneNumberArray">
                <div class="service-phonenumber-content">
                    <h1><i class='bx bx-phone-call'></i> {{ phoneNumber }}</h1>
                </div>
            </div>
        </div>
    </div>
</div>
