import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-section8-company',
  templateUrl: './section8-company.component.html',
  styleUrls: ['./section8-company.component.scss']
})
export class Section8CompanyComponent {

    descriptionTitle = 'Section 8 Company Registration';
    descriptionContent = 'Our Professionals will help you in getting your Company registered under Companies Act, 2013 and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Section 8 Company is registered for the purpose of Charitable or non-profitable purposes. It is licenced under section 8 of company act,2013. The main objective of the company is for promoting research, social-welfare, religion, charity, commerce, art, science, sport, education and protection of environment and any such activities in which the profits of the company can be utilized only for the promotion of the objects of the company. The dividend is not paid to the members.',
        'Section 8 company require minimum of 2 members and minimum of 2 directors. Section 8 company does not require a minimal capital. The company can function only by obtaining Central government licence. The government can also revoke the licence in case of any fraudulent objects are proved.'
    ]
    detailDocumentArray = [
        '2 Photos of each Director',
        'Identity Proof each Director',
        'Proposed Company Name',
        'Office Address Proof',
        'PAN copy of each Director',
        'Address Proof of each Director',
        'Rental Agreement or Sales Deed or Lease Deed',
    ];
    detailBenefitArray = [
        'Registered form of NPO and hence more trust factor to people donating into it',
        'Section 8 company can work on PAN India basis and does not require anyseparate approval'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your private limited registration form'
        },
        {
            item1: 'Obtain DSC & DIN',
            item2: 'DSC and DIN for all directors are required for the documentation of the private company'
        },
        {
            item1: 'Name Approval',
            item2: 'We make company name approval from the respective authority with the recommended document'
        },
        {
            item1: 'Filing MOA & AOA',
            item2: 'We will be filing MOA and AOA for the directors of company'
        },
        {
            item1: 'Company Certification',
            item2: 'Once the registration completed you will get certificate and CIN number'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'Name of Section 8 Registration',
            item2: 'Section 8 company names should end with words like Foundation, Association, Institution, Federation etc.,'
        },
        {
            item1: 'Objectives of Section 8 Company',
            item2: 'The main objective of Section 8 Company is to promote commerce, arts, science, sports, education, and research, and social welfare, protection of the environment or any other such object.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 15999,
            charge: 'all inclusive fees',
            features: [
                'Section 8 company registration with 2 DSC and DIN',
                'Incorporation fee',
                'PAN',
                'TAN',
                'Name approval',
                'Share certificates',
                'Company kit which include 5 copies of MOA'
            ]
        }
    ]

}
