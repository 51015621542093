import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-nidhi-company',
  templateUrl: './nidhi-company.component.html',
  styleUrls: ['./nidhi-company.component.scss']
})
export class NidhiCompanyComponent {

    descriptionTitle = 'Nidhi Company Registration';
    descriptionContent = 'Our Professionals will help you in getting your Company registered under Companies Act, 2013 and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Nidhi company is one of the Non-banking financial company (NBFC). However, it does not require a licence from the Reserve bank of India. A Nidhi company takes deposits from its members and lend to its members only for their mutual benefits. It is benefited by saving and utilizing the fund among their members.',
        'A Nidhi company is a separate legal entity and can own property and incur debts. A Nidhi company require a minimum of seven members out of which a minimum of three directors to incorporate. A Nidhi company requires a minimum of 5 lakh rupees as equity share capital to incorporate and the company can’t issue Preference shares. A Nidhi company is registered as a Public limited company and the last word of the company must have Nidhi limited.'
    ]
    detailDocumentArray = [
        '2 Photos of each Director',
        'Identity Proof each Director',
        'Proposed Company Name',
        'Office Address Proof',
        'Authorized Capital',
        'PAN copy of each Director',
        'Address Proof of each Director',
        'Rental Agreement or Sales Deed or Lease Deed',
        'Share holder percentage',
        'Paid-up Capital'
    ];
    detailBenefitArray = [
        'Minimum of paid-up capital requirement for Nidhi Company is very low compared to other Non-Banking Financial Companies. You just need 5,00,000/- and 7 members to get started, That would ease for all.',
        'You dont need to wait for an RBI Licence. Nidhi Company is the only loan company in India which can be started without RBI Approval/Licence. The Registration process is easy and can be done in 15 to 20 days.',
        'Trusted 3 people (almost from the same family) will be selected as directors, their PAN, Photo, ID, Address proof and any authorized proof of registered office is all you need for registration to be simple.',
        'Another Benefit of Nidhi Company is high rate interest of 7.5%. And Nidhi Company can earn max 20% interest on loans in reducing balance method.'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your private limited registration form'
        },
        {
            item1: 'Obtain DSC & DIN',
            item2: 'DSC and DIN for all directors are required for the documentation of the private company'
        },
        {
            item1: 'Name Approval',
            item2: 'We make company name approval from the respective authority with the recommended document'
        },
        {
            item1: 'Filing MOA & AOA',
            item2: 'We will be filing MOA and AOA for the directors of company'
        }
        ,
        {
            item1: 'Company Certification',
            item2: 'Once the registration completed you will get certificate and CIN number'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'What is the minimum of paid-up capital requirement for Nidhi Company?',
            item2: 'You just need 5,00,000/-'
        },
        {
            item1: 'Do you need RBI license?',
            item2: 'You don’t need to wait for an RBI Licence. Nidhi Company is the only loan company in India which can be started without RBI Approval/Licence.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 18499,
            charge: 'all inclusive fees',
            features: [
                'Nidhi limited company registration with 7 DSC and DIN',
                'Incorporation fee',
                'PAN',
                'TAN',
                'Name approval',
                'Share certificates',
                'Company kit which include 5 copies of MOA'
            ]
        }
    ]

}
