import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-partnership-firm',
  templateUrl: './partnership-firm.component.html',
  styleUrls: ['./partnership-firm.component.scss']
})
export class PartnershipFirmComponent {

    descriptionTitle = 'Partnership Firm Registration';
    descriptionContent = 'Our Professionals will help you in getting your Company registered under Companies Act, 2013 and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'A Partnership Firm is a common form of business constituted by two or more person to form a business and share the profits and losses. The Partnership firm is the simplest form of business that legally has no separate existence from its owners. It simply refers to a name given to the individual composing it. A partnership firm is common among small and medium-sized business as it is easy to set up the business.',
        'In a Partnership firm unlimited liability if given to the partners. The partnership is a contractual agreement formed by oral, unregistered or by written-among the partners by drafting a partnership deed and by registering it. It is always advisable to draft up a written partnership deed and register the firm with the Registrar of Firm as it may avoid some real-life legal consequences.'
    ]
    detailDocumentArray = [
        'Partnership Deed',
        'Identity Proof',
        'Partnership Firm PAN Copy',
        'Address Proof of the Partnership Firm',,
        'Rental Agreement or Sales Deed or Lease Deed',
    ];
    detailBenefitArray = [
        'Partnership Firm can be formed without any legal formality and expenses.',
        'Flexibility in the operations of the business.',
        'partnership firm is very well managed by all the partners as they take interest in the daily affairs of business due to the ownership, profit and control.',
        'In partnership, every partner bears the risks individually, thus the risk is shared'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting Partnership Deed',
            item2: 'Our Professionals will prepare your Agreement papers.'
        },
        {
            item1: 'Application to the Registrar of Firms',
            item2: 'We Prepare you Registration forms and process the needs.'
        },
        {
            item1: 'Name Filing Partnership deed',
            item2: 'We Register your partnership deed with the Registrar'
        },
        {
            item1: 'Certification',
            item2: 'The Registrar will issue the Certification'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'What is a Partnership Deed?',
            item2: 'It is a Legal document of a Partnership Firm. It includes all the details about its Formation, Management, functioning and resolution of the disputes among the partners of the partnership firm.'
        },
        {
            item1: 'Registration of a Partnership Deed?',
            item2: 'Legalizing the Deed and Registering under Indian Registration act of 1908'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'BASIC',
            amount: 1499,
            charge: 'all inclusive fees',
            features: [
                'Partnership Deed',
                'PAN Card'
            ]
        },
        {
            type: 'PREMIUM',
            amount: 2999,
            charge: 'all inclusive fees',
            features: [
                'Partnership Deed',
                'PAN Card',
                'Firm Registration'
            ]
        },
        {
            type: 'SUPREME',
            amount: 4999,
            charge: 'all inclusive fees',
            features: [
                'Partnership Deed',
                'PAN Card',
                'Firm Registration',
                'Income tax Filing(1 year less than 1 crore turnover)'
            ]
        }
    ]

}
