<div id="map">
    <iframe src="https://www.google.com/maps/embed/v1/place?q=ARAMA+MANAGEMENT+SERVICES+PVT+LTD,+Pasumarthy+Street,+KG+NTR+Heritage,+Viswanatha+Puram,+Kodambakkam,+Chennai,+Tamil+Nadu,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
</div>
<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <h3>KODAMBAKKAM</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">
                                Flat No. A-2, Swagath Apartments,
                                <br>
                                Ground Floor, No.1,
                                <br>
                                Pasumarthy Street,
                                <br>
                                Kodambakkam,
                                <br>
                                Chennai-600024.
                            </a>
                        </li>
                        <li>
                            <i class='bx bx-mobile-alt'></i>
                            <a href="tel:8056569875">+91 8056569875, +91 8489577005</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:04449507970">+044 49507970</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <h3>TAMBARAM</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">
                                Flat No. 1, Suriya Apartments,
                                <br>
                                1st Floor, D/No #H,
                                <br>
                                Sharma Street, Ganapathipuram,
                                <br>
                                East Tambaram,
                                <br>
                                Chennai-600059.</a>
                        </li>
                        <li>
                            <i class='bx bx-mobile-alt'></i>
                            <a href="tel:8056569875">+91 8056569875, +91 9840947970</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:04443117970">+044 43117970</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <h3>NAGERCOIL</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                                <a href="#" target="_blank">
                                    1st Floor, D/No: 18 C1/0,
                                    <br>
                                    Near Auto Stand,
                                    <br>
                                    Holy Cross College Road,
                                    <br>
                                    Punnainagar,
                                    <br>
                                    Nagercoil-629004.
                                </a>
                        </li>
                        <li>
                            <i class='bx bx-mobile-alt'></i>
                            <a href="tel:9600717970">+91 9600717970, 91 9500537970</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:9600717970">+04652 420874</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <h4 class="sub-title">Contact Us</h4>
            <h2>Drop us message for any Query</h2>
            <p>ARAMA is here to provide you more information whenever your business needs it. We will assist you to improve your business standard and get out best results with incredibly responsive to every feasible request.</p>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">Name is required.</div>
                                <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Your email address">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Your phone number">
                            <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject" placeholder="Your subject">
                            <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Your message..."></textarea>
                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div>
