import { Component, Input, OnInit } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-service-price-faq',
  templateUrl: './service-price-faq.component.html',
  styleUrls: ['./service-price-faq.component.scss']
})
export class ServicePriceFaqComponent implements OnInit {

  @Input() serviceFaqs: TwoDataSet[];
  @Input() servicePricings: Pricing[];
  constructor() { }

  ngOnInit(): void {
  }

}
