<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/arama/e-learning/elearning-1.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a >Business</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                By: <a class="d-inline-block">Sanjay</a>
                            </li>
                            <li><a>December 25, 2022</a></li>
                        </ul>
                        <h3><a class="d-inline-block">Commencement of Business Certificate</a></h3>
                        <a class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/arama/e-learning/elearning-2.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a >GST</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                By: <a class="d-inline-block">Gauri</a>
                            </li>
                            <li><a>January 10, 2023</a></li>
                        </ul>
                        <h3><a class="d-inline-block">Utilization Input Tax Credit Under GST</a></h3>
                        <a class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a class="d-block">
                            <img src="assets/img/arama/e-learning/elearning-3.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a >Tax</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                By: <a class="d-inline-block">Aaron</a>
                            </li>
                            <li><a>January 20, 2023</a></li>
                        </ul>
                        <h3><a class="d-inline-block">Requirement In Income Tax Return Forms</a></h3>
                        <a class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
