import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-gst-registration',
  templateUrl: './gst-registration.component.html',
  styleUrls: ['./gst-registration.component.scss']
})
export class GstRegistrationComponent {

    descriptionTitle = 'GST Registration';
    descriptionContent = 'Our Professionals Will help you in getting your GST registered and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Goods and Service Tax (GST)  is an Indirect Tax which has take over many Indirect Taxes in India. Its hugely enhancing simplicity of doing business and expanding the taxpayer base in India acquire a large number of private ventures in India. By canceling various Tax systems into a single indirect tax system, Tax difficulties would be diminished while tax base is expanded significantly.',
        'According to this GST system, all business engaged with purchasing or selling goods or both are required to enlist for GST. Due to this system the tax payers burden of various tax is reduced to one.'
    ]
    detailDocumentArray = [
        'In case of Private Limited or One Person Company - PAN Card of Company, COI, MOA & AOA of Company, Board Resolution with Director’s ID, photo and address proof, Bank Statement, EB Card and Rental Agreement',
        'In case of Sole Proprietorship Firm - Photo, PAN Card, Address Proof of the Proprietor, Bank Statement, EB Card and Rental Agreement',
        'In case of Partnership Firm - Photo, PAN Card with the deed, Partnership registration certificate & partners id, photo & address proof, Bank Statement, EB Card and Rental Agreement',
        'In case of Limited Liability Partnership - PAN Card of Company, COI of Company, Deed + Partner’s ID, photo and address proof, Bank Statement, EB Card and Rental Agreement'
    ];
    detailBenefitArray = [
        'Simple compliance and procedure',
        'Similarity in tax rates and Structures',
        'Increase competitiveness',
        'Relief from overall tax burden for the customer'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your GST registration form'
        },
        {
            item1: 'Gathering Documents',
            item2: 'We will gather the necessary documents from you for the registration process'
        },
        {
            item1: 'Document Filing',
            item2: 'Once we collect the necessary documents we would be filing for the GST submission'
        },
        {
            item1: 'GSTIN Code',
            item2: 'After the successful registration we would be providing the GSTIN code'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'How long it may take to get registered  in GST?',
            item2: '30 days it may take to complete its registration formalities and obtain the registration.'
        },
        {
            item1: 'Is there area based exemption under GST?',
            item2: 'No, there is no area based exemptions.'
        },
        {
            item1: 'Is registration required separately by same business in each state?',
            item2: 'Only one registration is required.'
        },
        {
            item1: 'A deal in exempted product having own brand then does GST registration is required?',
            item2: 'No, registration is not required to be obtained.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'BASIC',
            amount: 999,
            charge: 'all inclusive fees',
            features: [
                'GST Certificate'
            ]
        },
        {
            type: 'PREMIUM',
            amount: 4999,
            charge: 'all inclusive fees',
            features: [
                'GST Certificate',
                'GST Filing for 1 year',
                'GSTR 1 for 1 year',
                'GSTR 3B for 1 year'
            ]
        },
        {
            type: 'SUPREME',
            amount: 9999,
            charge: 'all inclusive fees',
            features: [
                'GST Certificate',
                'GST Filing for 1 year',
                'GSTR 1 for 1 year',
                'GSTR 3B for 1 year',
                'GSTR 9 Annual Return for 1 year',
                'Income tax Filing for 1 year less than 1 crore turnover'
            ]
        }
    ]

}
