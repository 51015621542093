<div class="service-faq-area">
    <div class="container-fluid">
        <h1>Frequently Asked Questions</h1>
        <br><br>
        <div class="row">
            <div class="col-md-12">
                <div class="faq-accordion faq-accordion-style-two">
                    <accordion>
                        <accordion-group *ngFor="let serviceFaq of serviceFaqs; let indexOfelement=index;" heading="{{ serviceFaq.item1 }}" [isOpened]="indexOfelement === 0 ? true : false">
                            <p>{{ serviceFaq.item2 }}</p>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
        </div>
        <br><br>
    </div>
</div>
