import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { HomePageTwoComponent } from './components/pages/home-page-two/home-page-two.component';
import { HomePageThreeComponent } from './components/pages/home-page-three/home-page-three.component';
import { HomePageFourComponent } from './components/pages/home-page-four/home-page-four.component';
import { HomePageFiveComponent } from './components/pages/home-page-five/home-page-five.component';
import { HomePageSixComponent } from './components/pages/home-page-six/home-page-six.component';
import { HomePageSevenComponent } from './components/pages/home-page-seven/home-page-seven.component';
import { AboutPageOneComponent } from './components/pages/about-page-one/about-page-one.component';
import { AboutPageTwoComponent } from './components/pages/about-page-two/about-page-two.component';
import { InstructorsPageOneComponent } from './components/pages/instructors-page-one/instructors-page-one.component';
import { InstructorsPageTwoComponent } from './components/pages/instructors-page-two/instructors-page-two.component';
import { InstructorsPageThreeComponent } from './components/pages/instructors-page-three/instructors-page-three.component';
import { InstructorsDetailsPageComponent } from './components/pages/instructors-details-page/instructors-details-page.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { EventsDetailsPageComponent } from './components/pages/events-details-page/events-details-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { PricingPageComponent } from './components/pages/pricing-page/pricing-page.component';
import { FeedbackPageComponent } from './components/pages/feedback-page/feedback-page.component';
import { PartnerPageComponent } from './components/pages/partner-page/partner-page.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { RegisterPageComponent } from './components/pages/register-page/register-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { BlogGridPageComponent } from './components/pages/blog-grid-page/blog-grid-page.component';
import { BlogRightSidebarPageComponent } from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
import { BlogFullWidthPageComponent } from './components/pages/blog-full-width-page/blog-full-width-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { ShopGridPageComponent } from './components/pages/shop-grid-page/shop-grid-page.component';
import { ShopFullWidthPageComponent } from './components/pages/shop-full-width-page/shop-full-width-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CategoryPageOneComponent } from './components/pages/category-page-one/category-page-one.component';
import { CategoryPageTwoComponent } from './components/pages/category-page-two/category-page-two.component';
import { CategoryPageThreeComponent } from './components/pages/category-page-three/category-page-three.component';
import { CoursesListPageComponent } from './components/pages/courses-list-page/courses-list-page.component';
import { CoursesGridStyleOneColumnsTwoPageComponent } from './components/pages/courses-grid-style-one-columns-two-page/courses-grid-style-one-columns-two-page.component';
import { CoursesGridStyleOneColumnsThreePageComponent } from './components/pages/courses-grid-style-one-columns-three-page/courses-grid-style-one-columns-three-page.component';
import { CoursesGridStyleOneColumnsFourPageComponent } from './components/pages/courses-grid-style-one-columns-four-page/courses-grid-style-one-columns-four-page.component';
import { CoursesGridStyleTwoColumnsTwoPageComponent } from './components/pages/courses-grid-style-two-columns-two-page/courses-grid-style-two-columns-two-page.component';
import { CoursesGridStyleTwoColumnsThreePageComponent } from './components/pages/courses-grid-style-two-columns-three-page/courses-grid-style-two-columns-three-page.component';
import { CoursesGridStyleTwoColumnsFourPageComponent } from './components/pages/courses-grid-style-two-columns-four-page/courses-grid-style-two-columns-four-page.component';
import { CoursesGridStyleThreeColumnsFourPageComponent } from './components/pages/courses-grid-style-three-columns-four-page/courses-grid-style-three-columns-four-page.component';
import { CoursesGridStyleThreeColumnsThreePageComponent } from './components/pages/courses-grid-style-three-columns-three-page/courses-grid-style-three-columns-three-page.component';
import { CoursesGridStyleThreeColumnsTwoPageComponent } from './components/pages/courses-grid-style-three-columns-two-page/courses-grid-style-three-columns-two-page.component';
import { CoursesDetailsPageComponent } from './components/pages/courses-details-page/courses-details-page.component';
import { MyDashboardPageComponent } from './components/pages/my-dashboard-page/my-dashboard-page.component';
import { OrdersPageComponent } from './components/pages/orders-page/orders-page.component';
import { DownloadsPageComponent } from './components/pages/downloads-page/downloads-page.component';
import { EditAddressPageComponent } from './components/pages/edit-address-page/edit-address-page.component';
import { EditAccountPageComponent } from './components/pages/edit-account-page/edit-account-page.component';
import { EditBillingAddressPageComponent } from './components/pages/edit-billing-address-page/edit-billing-address-page.component';
import { EditShippingAddressPageComponent } from './components/pages/edit-shipping-address-page/edit-shipping-address-page.component';
import { HomePageEightComponent } from './components/pages/home-page-eight/home-page-eight.component';
import { HomePageNineComponent } from './components/pages/home-page-nine/home-page-nine.component';
import { HomePageTenComponent } from './components/pages/home-page-ten/home-page-ten.component';
import { HomePageElevenComponent } from './components/pages/home-page-eleven/home-page-eleven.component';
import { HomePageTwelveComponent } from './components/pages/home-page-twelve/home-page-twelve.component';
import { HomePageThirteenComponent } from './components/pages/home-page-thirteen/home-page-thirteen.component';
import { HomePageFourteenComponent } from './components/pages/home-page-fourteen/home-page-fourteen.component';
import { ProductPageComponent } from './components/pages/product-page/product-page.component';
import { ServiceComponent } from './components/arama/service/service.component';
import { OfferComponent } from './components/arama/offer/offer.component';
import { BlogComponent } from './components/arama/blog/blog.component';
import { BlogDetailComponent } from './components/arama/blog/blog-detail/blog-detail.component';
import { EventComponent } from './components/arama/event/event.component';
import { ContactComponent } from './components/arama/contact/contact.component';
import { ErrorComponent } from './components/arama/error/error.component';
import { HomeComponent } from './components/arama/home/home.component';
import { PrivateLimitedCompanyComponent } from './components/arama/page/content/CompanyAndFirmRegistration/private-limited-company/private-limited-company.component';
import { OnePersonCompanyComponent } from './components/arama/page/content/CompanyAndFirmRegistration/one-person-company/one-person-company.component';
import { PublicLimitedCompanyComponent } from './components/arama/page/content/CompanyAndFirmRegistration/public-limited-company/public-limited-company.component';
import { NidhiCompanyComponent } from './components/arama/page/content/CompanyAndFirmRegistration/nidhi-company/nidhi-company.component';
import { Section8CompanyComponent } from './components/arama/page/content/CompanyAndFirmRegistration/section8-company/section8-company.component';
import { ProducerCompanyComponent } from './components/arama/page/content/CompanyAndFirmRegistration/producer-company/producer-company.component';
import { ForeignSubsidiaryCompanyComponent } from './components/arama/page/content/CompanyAndFirmRegistration/foreign-subsidiary-company/foreign-subsidiary-company.component';
import { LimitedLiabilityPartnershipComponent } from './components/arama/page/content/CompanyAndFirmRegistration/limited-liability-partnership/limited-liability-partnership.component';
import { PartnershipFirmComponent } from './components/arama/page/content/CompanyAndFirmRegistration/partnership-firm/partnership-firm.component';
import { SoleProprietorComponent } from './components/arama/page/content/CompanyAndFirmRegistration/sole-proprietor/sole-proprietor.component';
import { AdCodeRegistrationComponent } from './components/arama/page/content/BusinessRegistration/ad-code-registration/ad-code-registration.component';
import { DigitalSignatureCertificateComponent } from './components/arama/page/content/BusinessRegistration/digital-signature-certificate/digital-signature-certificate.component';
import { DrugLicenseComponent } from './components/arama/page/content/BusinessRegistration/drug-license/drug-license.component';
import { FssaiLicenseComponent } from './components/arama/page/content/BusinessRegistration/fssai-license/fssai-license.component';
import { ImportExportCodeComponent } from './components/arama/page/content/BusinessRegistration/import-export-code/import-export-code.component';
import { IsoRegistrationComponent } from './components/arama/page/content/BusinessRegistration/iso-registration/iso-registration.component';
import { MsmeRegistrationComponent } from './components/arama/page/content/BusinessRegistration/msme-registration/msme-registration.component';
import { ProfessionalTaxRegistrationComponent } from './components/arama/page/content/BusinessRegistration/professional-tax-registration/professional-tax-registration.component';
import { ShopEstablishmentComponent } from './components/arama/page/content/BusinessRegistration/shop-establishment/shop-establishment.component';
import { TradeLicenseRegistrationComponent } from './components/arama/page/content/BusinessRegistration/trade-license-registration/trade-license-registration.component';
import { FireLicenseComponent } from './components/arama/page/content/BusinessRegistration/fire-license/fire-license.component';
import { FactoryLicenseComponent } from './components/arama/page/content/BusinessRegistration/factory-license/factory-license.component';
import { GstAnnualReturnFilingComponent } from './components/arama/page/content/Gst/gst-annual-return-filing/gst-annual-return-filing.component';
import { GstAppealComponent } from './components/arama/page/content/Gst/gst-appeal/gst-appeal.component';
import { GstCancellationComponent } from './components/arama/page/content/Gst/gst-cancellation/gst-cancellation.component';
import { GstLutFilingComponent } from './components/arama/page/content/Gst/gst-lut-filing/gst-lut-filing.component';
import { GstRegistrationComponent } from './components/arama/page/content/Gst/gst-registration/gst-registration.component';
import { GstReturnFilingComponent } from './components/arama/page/content/Gst/gst-return-filing/gst-return-filing.component';
import { Itr1ReturnFilingComponent } from './components/arama/page/content/IncomeTax/itr1-return-filing/itr1-return-filing.component';
import { Itr2ReturnFilingComponent } from './components/arama/page/content/IncomeTax/itr2-return-filing/itr2-return-filing.component';
import { Itr3ReturnFilingComponent } from './components/arama/page/content/IncomeTax/itr3-return-filing/itr3-return-filing.component';
import { Itr4ReturnFilingComponent } from './components/arama/page/content/IncomeTax/itr4-return-filing/itr4-return-filing.component';
import { Itr5ReturnFilingComponent } from './components/arama/page/content/IncomeTax/itr5-return-filing/itr5-return-filing.component';
import { Itr6ReturnFilingComponent } from './components/arama/page/content/IncomeTax/itr6-return-filing/itr6-return-filing.component';
import { Itr7ReturnFilingComponent } from './components/arama/page/content/IncomeTax/itr7-return-filing/itr7-return-filing.component';
import { PfReturnFilingComponent } from './components/arama/page/content/IncomeTax/pf-return-filing/pf-return-filing.component';
import { TdsReturnFilingComponent } from './components/arama/page/content/IncomeTax/tds-return-filing/tds-return-filing.component';
import { LimitedLiabilityCompanyComplianceComponent } from './components/arama/page/content/AnnualCompliance/limited-liability-company-compliance/limited-liability-company-compliance.component';
import { PartnershipComplianceComponent } from './components/arama/page/content/AnnualCompliance/partnership-compliance/partnership-compliance.component';
import { PrivateLimitedCompanyComplianceComponent } from './components/arama/page/content/AnnualCompliance/private-limited-company-compliance/private-limited-company-compliance.component';
import { ProprietorComplianceComponent } from './components/arama/page/content/AnnualCompliance/proprietor-compliance/proprietor-compliance.component';
import { AddDirectorComponent } from './components/arama/page/content/BusinessCompliance/add-director/add-director.component';
import { AddressChangeComponent } from './components/arama/page/content/BusinessCompliance/address-change/address-change.component';
import { AppointmentOfAuditorComponent } from './components/arama/page/content/BusinessCompliance/appointment-of-auditor/appointment-of-auditor.component';
import { IncreaseAuthorizedCapitalComponent } from './components/arama/page/content/BusinessCompliance/increase-authorized-capital/increase-authorized-capital.component';
import { MoaAmendmentComponent } from './components/arama/page/content/BusinessCompliance/moa-amendment/moa-amendment.component';
import { NameChangeComponent } from './components/arama/page/content/BusinessCompliance/name-change/name-change.component';
import { RemovalOfAuditorComponent } from './components/arama/page/content/BusinessCompliance/removal-of-auditor/removal-of-auditor.component';
import { RemoveDirectorComponent } from './components/arama/page/content/BusinessCompliance/remove-director/remove-director.component';
import { ShareTransferComponent } from './components/arama/page/content/BusinessCompliance/share-transfer/share-transfer.component';
import { WindingUpCompanyComponent } from './components/arama/page/content/BusinessCompliance/winding-up-company/winding-up-company.component';
import { CopyrightRegistrationComponent } from './components/arama/page/content/BusinessProtection/copyright-registration/copyright-registration.component';
import { DesignRegistrationComponent } from './components/arama/page/content/BusinessProtection/design-registration/design-registration.component';
import { IntellectualPropertyDisputeComponent } from './components/arama/page/content/BusinessProtection/intellectual-property-dispute/intellectual-property-dispute.component';
import { PatentRegistrationComponent } from './components/arama/page/content/BusinessProtection/patent-registration/patent-registration.component';
import { TrademarkAssignmentComponent } from './components/arama/page/content/BusinessProtection/trademark-assignment/trademark-assignment.component';
import { TrademarkObjectionComponent } from './components/arama/page/content/BusinessProtection/trademark-objection/trademark-objection.component';
import { TrademarkRectificationComponent } from './components/arama/page/content/BusinessProtection/trademark-rectification/trademark-rectification.component';
import { TrademarkRegistrationComponent } from './components/arama/page/content/BusinessProtection/trademark-registration/trademark-registration.component';
import { TrademarkRenewalComponent } from './components/arama/page/content/BusinessProtection/trademark-renewal/trademark-renewal.component';
import { ARegistrationComponent } from './components/arama/page/content/OtherRegistration/aregistration/aregistration.component';
import { FcraRegistrationComponent } from './components/arama/page/content/OtherRegistration/fcra-registration/fcra-registration.component';
import { GRegistrationComponent } from './components/arama/page/content/OtherRegistration/gregistration/gregistration.component';
import { NgoRegistrationComponent } from './components/arama/page/content/OtherRegistration/ngo-registration/ngo-registration.component';
import { Section8RegistrationComponent } from './components/arama/page/content/OtherRegistration/section8-registration/section8-registration.component';
import { SocietyRegistrationComponent } from './components/arama/page/content/OtherRegistration/society-registration/society-registration.component';
import { TrustRegistrationComponent } from './components/arama/page/content/OtherRegistration/trust-registration/trust-registration.component';
import { EsiRegistrationComponent } from './components/arama/page/content/BusinessRegistration/esi-registration/esi-registration.component';
import { PfRegistrationComponent } from './components/arama/page/content/BusinessRegistration/pf-registration/pf-registration.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'home', component: HomePageOneComponent},
    {path: 'course-portal-online', component: HomePageTwoComponent},
    {path: 'distant-learning', component: HomePageThreeComponent},
    {path: 'modern-teaching', component: HomePageFourComponent},
    {path: 'online-schooling', component: HomePageFiveComponent},
    {path: 'learning-school-online', component: HomePageSixComponent},
    {path: 'remote-training-online', component: HomePageSevenComponent},
    {path: 'business-coaching', component: HomePageEightComponent},
    {path: 'health-coaching', component: HomePageNineComponent},
    {path: 'language-school', component: HomePageTenComponent},
    {path: 'gym-coaching', component: HomePageElevenComponent},
    {path: 'online-motivation-course', component: HomePageTwelveComponent},
    {path: 'online-education-course', component: HomePageThirteenComponent},
    {path: 'online-training-course', component: HomePageFourteenComponent},
    {path: 'about-style-1', component: AboutPageOneComponent},
    {path: 'about-style-2', component: AboutPageTwoComponent},
    {path: 'instructor-1', component: InstructorsPageOneComponent},
    {path: 'instructor-2', component: InstructorsPageTwoComponent},
    {path: 'instructor-3', component: InstructorsPageThreeComponent},
    {path: 'single-instructor', component: InstructorsDetailsPageComponent},
    {path: 'gallery', component: GalleryPageComponent},
    {path: 'events', component: EventsPageComponent},
    {path: 'single-events', component: EventsDetailsPageComponent},
    {path: 'pricing', component: PricingPageComponent},
    {path: 'feedback', component: FeedbackPageComponent},
    {path: 'partner', component: PartnerPageComponent},
    {path: 'login', component: LoginPageComponent},
    {path: 'register', component: RegisterPageComponent},
    {path: 'faqs', component: FaqPageComponent},
    {path: 'error-404', component: ErrorPageComponent},
    {path: 'coming-soon', component: ComingSoonPageComponent},
    {path: 'courses-category-style-1', component: CategoryPageOneComponent},
    {path: 'courses-category-style-2', component: CategoryPageTwoComponent},
    {path: 'courses-category-style-3', component: CategoryPageThreeComponent},
    {path: 'courses-list', component: CoursesListPageComponent},
    {path: 'courses-2-columns-style-1', component: CoursesGridStyleOneColumnsTwoPageComponent},
    {path: 'courses-3-columns-style-1', component: CoursesGridStyleOneColumnsThreePageComponent},
    {path: 'courses-4-columns-style-1', component: CoursesGridStyleOneColumnsFourPageComponent},
    {path: 'courses-2-columns-style-2', component: CoursesGridStyleTwoColumnsTwoPageComponent},
    {path: 'courses-3-columns-style-2', component: CoursesGridStyleTwoColumnsThreePageComponent},
    {path: 'courses-4-columns-style-2', component: CoursesGridStyleTwoColumnsFourPageComponent},
    {path: 'courses-2-columns-style-3', component: CoursesGridStyleThreeColumnsTwoPageComponent},
    {path: 'courses-3-columns-style-3', component: CoursesGridStyleThreeColumnsThreePageComponent},
    {path: 'courses-4-columns-style-3', component: CoursesGridStyleThreeColumnsFourPageComponent},
    {path: 'single-courses', component: CoursesDetailsPageComponent},
    {path: 'my-dashboard', component: MyDashboardPageComponent},
    {path: 'orders', component: OrdersPageComponent},
    {path: 'downloads', component: DownloadsPageComponent},
    {path: 'edit-address', component: EditAddressPageComponent},
    {path: 'edit-account', component: EditAccountPageComponent},
    {path: 'edit-billing-address', component: EditBillingAddressPageComponent},
    {path: 'edit-shipping-address', component: EditShippingAddressPageComponent},
    {path: 'blog-style-1', component: BlogGridPageComponent},
    {path: 'blog-style-2', component: BlogRightSidebarPageComponent},
    {path: 'blog-style-3', component: BlogFullWidthPageComponent},
    {path: 'single-blog', component: BlogDetailsPageComponent},
    {path: 'shop-grid', component: ShopGridPageComponent},
    {path: 'shop-grid-fullwidth', component: ShopFullWidthPageComponent},
    {path: 'single-products', component: ProductsDetailsPageComponent},
    {path: 'product', component: ProductPageComponent},
    {path: 'cart', component: CartPageComponent},
    {path: 'checkout', component: CheckoutPageComponent},
    {path: 'contact', component: ContactPageComponent},
    {path: 'service-design', component: ServiceComponent},
    {path: 'offer', component: OfferComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-detail', component: BlogDetailComponent},
    {path: 'eventtest', component: EventComponent},
    {path: 'contacttest', component: ContactComponent},
    {path: 'privatelimitedcompany', component: PrivateLimitedCompanyComponent},
    {path: 'onepersoncompany', component: OnePersonCompanyComponent},
    {path: 'publiclimitedcompany', component: PublicLimitedCompanyComponent},
    {path: 'nidhicompany', component: NidhiCompanyComponent},
    {path: 'section8company', component: Section8CompanyComponent},
    {path: 'producercompany', component: ProducerCompanyComponent},
    {path: 'foreignsubsidiarycompany', component: ForeignSubsidiaryCompanyComponent},
    {path: 'limitedliabilitypartnership', component: LimitedLiabilityPartnershipComponent},
    {path: 'partnershipfirm', component: PartnershipFirmComponent},
    {path: 'soleproprietor', component: SoleProprietorComponent},
    {path: 'professionaltaxregistration', component: ProfessionalTaxRegistrationComponent},
    {path: 'shopestablishment', component: ShopEstablishmentComponent},
    {path: 'importexportcode', component: ImportExportCodeComponent},
    {path: 'fssailicense', component: FssaiLicenseComponent},
    {path: 'firelicense', component: FireLicenseComponent},
    {path: 'factoryLicense', component: FactoryLicenseComponent},
    {path: 'esiregistration', component: EsiRegistrationComponent},
    {path: 'pfregistration', component: PfRegistrationComponent},
    {path: 'msmeregistration', component: MsmeRegistrationComponent},
    {path: 'isoregistration', component: IsoRegistrationComponent},
    {path: 'adcoderegistration', component: AdCodeRegistrationComponent},
    {path: 'tradelicenseregistration', component: TradeLicenseRegistrationComponent},
    {path: 'druglicense', component: DrugLicenseComponent},
    {path: 'digitalsignaturecertificate', component: DigitalSignatureCertificateComponent},
    {path: 'gstregistration', component: GstRegistrationComponent},
    {path: 'gstlutfiling', component: GstLutFilingComponent},
    {path: 'gstcancellation', component: GstCancellationComponent},
    {path: 'gstreturnfiling', component: GstReturnFilingComponent},
    {path: 'gstannualreturnfiling', component: GstAnnualReturnFilingComponent},
    {path: 'gstappeal', component: GstAppealComponent},
    {path: 'itr1returnfiling', component: Itr1ReturnFilingComponent},
    {path: 'itr2returnfiling', component: Itr2ReturnFilingComponent},
    {path: 'itr3returnfiling', component: Itr3ReturnFilingComponent},
    {path: 'itr4returnfiling', component: Itr4ReturnFilingComponent},
    {path: 'itr5returnfiling', component: Itr5ReturnFilingComponent},
    {path: 'itr6returnfiling', component: Itr6ReturnFilingComponent},
    {path: 'itr7returnfiling', component: Itr7ReturnFilingComponent},
    {path: 'tdsreturnfiling', component: TdsReturnFilingComponent},
    {path: 'pfreturnfiling', component: PfReturnFilingComponent},
    {path: 'namechange', component: NameChangeComponent},
    {path: 'addresschange', component: AddressChangeComponent},
    {path: 'adddirector', component: AddDirectorComponent},
    {path: 'removedirector', component: RemoveDirectorComponent},
    {path: 'increaseauthorizedcapital', component: IncreaseAuthorizedCapitalComponent},
    {path: 'sharetransfer', component: ShareTransferComponent},
    {path: 'moaamendment', component: MoaAmendmentComponent},
    {path: 'appointmentofauditor', component: AppointmentOfAuditorComponent},
    {path: 'removalofauditor', component: RemovalOfAuditorComponent},
    {path: 'windingupcompany', component: WindingUpCompanyComponent},
    {path: 'privatelimitedcompanycompliance', component: PrivateLimitedCompanyComplianceComponent},
    {path: 'limitedliabilitycompanycompliance', component: LimitedLiabilityCompanyComplianceComponent},
    {path: 'partnershipcompliance', component: PartnershipComplianceComponent},
    {path: 'proprietorcompliance', component: ProprietorComplianceComponent},
    {path: 'trademarkregistration', component: TrademarkRegistrationComponent },
    {path: 'trademarkrenewal', component: TrademarkRenewalComponent },
    {path: 'trademarkobjection', component: TrademarkObjectionComponent },
    {path: 'trademarkrectification', component: TrademarkRectificationComponent },
    {path: 'trademarkassignment', component: TrademarkAssignmentComponent },
    {path: 'copyrightregistration', component: CopyrightRegistrationComponent },
    {path: 'designregistration', component: DesignRegistrationComponent },
    {path: 'patentregistration', component: PatentRegistrationComponent },
    {path: 'intellectualpropertydispute', component: IntellectualPropertyDisputeComponent },
    {path: 'trustregistration', component: TrustRegistrationComponent },
    {path: 'ngoregistration', component: NgoRegistrationComponent },
    {path: 'societyregistration', component: SocietyRegistrationComponent },
    {path: 'section8registration', component: Section8RegistrationComponent  },
    {path: 'fcraregistration', component: FcraRegistrationComponent },
    {path: '80g12aregistration', component: GRegistrationComponent },
    {path: '12aaregistration', component: ARegistrationComponent },

    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
