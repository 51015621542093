import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-tds-return-filing',
  templateUrl: './tds-return-filing.component.html',
  styleUrls: ['./tds-return-filing.component.scss']
})
export class TdsReturnFilingComponent {

    descriptionTitle = 'TDS Return Filing';
    descriptionContent = 'Our Professionals will help you in getting your TDS Return Filing and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'TDS (Tax Deduction at source) is to collect tax from the very source of income. Tax Deduction and Collection Number (TAN) is mandatory to be obtained by the persons who are responsible for Tax Deduction at Source (TDS) or Tax Collection at Source (TCS) on behalf of the Government.',
        'The deductor is a person who is responsible to deduct the tax at source of the person (deductee) has to deposit into the account of the Central Government – quoting the TAN number. The deduction from the deductee’s income tax has been deducted at source would be entitled to get credit of the amount so deducted on the basis of Form 26AS or TDS certificate issued by the dedicator. Salaried Individuals are not required to file TDS. Entities like Private Limited Company, LLP, etc., and Proprietorship businesses must deduct tax at source while making payments like salary, contractor or sub-contractors payments, Rental payment above Rs.1,80,000 per annum, etc.'
    ]
    detailDocumentArray = [
        'Identity Proof',
        'PAN Card',
        'Bank Statement',
        'TDS Certificate'
    ];
    detailBenefitArray = [
        'The Tax Collection base is widened',
        'The burden of responsibility of the Tax Collection Agencies and the Deductor are lessened',
        'It is convenient for the deductee as Tax is automatically deducted'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your TDS return form'
        },
        {
            item1: 'Gathering Documents',
            item2: 'Gathering the requirement documents and formatting it accordingly to requirement'
        },
        {
            item1: 'FVU Submission',
            item2: 'Submission of formatted documents to FVU for the validation and verification'
        },
        {
            item1: 'TDS Return',
            item2: 'After the successful validation the file document and wait for TDS return'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'Does TAN needed?',
            item2: 'Yes TAN is neeeded for TDS return filing.'
        },
        {
            item1: 'Salaried Individuals need to file TDS return?',
            item2: 'No, salaried Individuals are not required to file TDS return.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 149,
            charge: 'all exclusive fees',
            features: [
                'TDS Return filed'
            ]
        }
    ]


}
