<app-service-description
[serviceDescriptionTitle]="descriptionTitle"
[serviceDescriptionContent]="descriptionContent">
</app-service-description>

<app-service-highlight></app-service-highlight>

<app-service-detail
[serviceDetailDescriptions]="detailDescriptionArray"
[serviceDetailDocuments]="detailDocumentArray"
[serviceDetailBenefits]="detailBenefitArray"
[serviceDetailWeDos]="detailWeDoArray">
</app-service-detail>

<app-service-phonenumber></app-service-phonenumber>

<app-service-pricing
[servicePricings]="pricingArray">
</app-service-pricing>

<app-service-faq
[serviceFaqs]="faqArray">
</app-service-faq>
