import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-digital-signature-certificate',
  templateUrl: './digital-signature-certificate.component.html',
  styleUrls: ['./digital-signature-certificate.component.scss']
})
export class DigitalSignatureCertificateComponent {

    descriptionTitle = 'Digital Signature Certificate';
    descriptionContent = 'Our Professionals will help you in getting your Digital Signature Certificate and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Digital Signature Certificate(DSC) is an electronic format of the physical signature. Digital Signatures are required for online transactions such as  Income Tax E-Filing, Company or LLP Incorporation, Filing Annual Return, E-Tenders, etc. Class I, Class II and Class III are the three types of Digital Signature.',
        'Digital Signatures maintained in the form of a USB E-Token, wherein the Digital Signature. The Digital certificate is saved in a USB Drive and could be accessed through the computer to sign documents electronically.'
    ]
    detailDocumentArray = [
        'One Passport size Photo',
        'Proof of Identity',
        'PAN Card',
        'Address Proof any one of Director'
    ];
    detailBenefitArray = [
        'Class I type of DSCs are only required for securing email communication',
        'Class II type of DSCs are required for Company or LLP Incorporation, IT Return e-Filing, Obtaining DIN (Director Identification Number) or DPIN (Designated Partner Identification Number), and filing other forms with the Ministry of Corporate Affairs and Income Tax Department',
        'Class III type of DSCs is required for E-Tendering and for participating in E-Auctions'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Select the Type of Entity',
            item2: 'Selecting the type of Entity by logging on'
        },
        {
            item1: 'Filling the Details',
            item2: 'Filling all the details by downloading the Form'
        },
        {
            item1: 'Providing Documents',
            item2: 'We collect the documents from you and attach with the form'
        },
        {
            item1: 'Payment for DSC',
            item2: 'For the application of DSC , a DD/cheque must be obtained towards payment for application of DSC in the name of the Local Registration Authority where you are going to submit your application for verification'
        },
        {
            item1: 'Post the Document Required',
            item2: 'Enclose the Form with documents and DD/Cheque in an envelop and post to the designated address of the LRA for processing'
        },
        {
            item1: 'Certification',
            item2: 'You will receive your DSC in a Drive'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'Can a DSC be Forged?',
            item2: 'The Digital signature is more secure than the manually written signature which could be possibly imitated by any person. DSC cannot be practically forged.'
        },
        {
            item1: 'Validity of Digital Signature?',
            item2: 'It can be chosen by the individual, whether 1 year or 2 years from the date it has been issued.'
        },
        {
            item1: 'Digital Signature provided by us',
            item2: 'All types of digital signatures through: Class 2 Individual of class 2a, Class 2 Organization of class 2b, Class 3 Individual  of class 3a, Class 3 Organization of class 3b and DGFT'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 499,
            charge: 'all inclusive fees',
            features: [
                'Class 1 Digital Signature'
            ]
        },
        {
            type: 'Starting At',
            amount: 999,
            charge: 'all inclusive fees',
            features: [
                'Class 2 Digital Signature'
            ]
        },
        {
            type: 'Starting At',
            amount: 4999,
            charge: 'all inclusive fees',
            features: [
                'Class 3 Digital Signature'
            ]
        }
    ]

}
