import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-private-limited-company',
  templateUrl: './private-limited-company.component.html',
  styleUrls: ['./private-limited-company.component.scss']
})
export class PrivateLimitedCompanyComponent {

    descriptionTitle = 'Private Limited Company Registration';
    descriptionContent = 'Our Professionals will help you in getting your Company registered under Companies Act, 2013 and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'The private limited company is the most popular type of formation of a corporate legal entity which is governed under the Ministry of Corporate affairs under Companies Act, 2013 and the company’s incorporation rules, 2014. Private limited companies are also a popular entity among foreign investors to form a subsidiary, a joint venture or 100 % owned company in India. Private Limited company can be incorporated by a minimum of 2 shareholders and a maximum of 200 shareholders. The shareholders can be a natural person or a corporate legal entity which includes foreign companies. A Private limited company should have a minimum of 2 directors and a maximum of 15 directors.',
        'The Private Limited Company shares can be sold or transferred only by the choice of the owner which could be owned by founders, management or an entity. The shares of a Private limited company cannot be sold in an open market like public Limited companies. The Private limited company is common among most of the smaller and medium-sized businesses.',
        'Arama is a leading Consultancy in South India for services such as Company Registration. Arama helps you to register Private limited company, one person company, Nidhi company, section 8 company, producer company or Indian subsidiary at a reasonable price within 10 to 15 working days.'
    ]
    detailDocumentArray = [
        '2 Photos of each Director',
        'Identity Proof each Director',
        'Proposed Company Name',
        'Office Address Proof',
        'Authorized Capital',
        'PAN copy of each Director',
        'Address Proof of each Director',
        'Rental Agreement or Sales Deed or Lease Deed',
        'Share holder percentage',
        'Paid-up Capital'
    ];
    detailBenefitArray = [
        'Restricts any invitation to the public to subscribe to any company securities',
        'Restricts the right to transfer shares',
        'Attractive to Investors'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your private limited registration form'
        },
        {
            item1: 'Obtain DSC & DIN',
            item2: 'DSC and DIN for all directors are required for the documentation of the private company'
        },
        {
            item1: 'Name Approval',
            item2: 'We make company name approval from the respective authority with the recommended document'
        },
        {
            item1: 'Filing MOA & AOA',
            item2: 'We will be filing MOA and AOA for the directors of company'
        }
        ,
        {
            item1: 'Company Certification',
            item2: 'Once the registration completed you will get certificate and CIN number'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'What are the requirements to be a director?',
            item2: 'Any person above 21 years can become a director. Non-residents can also become director of Indian companies.'
        },
        {
            item1: 'I already have my digital signature certificate and DIN. Will the package value remain the same?',
            item2: 'In case, you already have a DSC and DIN, our experts will offer you some concession accordingly on the above package.'
        },
        {
            item1: 'What is a digital signature certificate (DSC)?',
            item2: 'Digital signature is process to authenticate and validate records electronically. DSC is required for every director of the company as the Ministry of Corporate Affairs (MCA) mandates digital signature of directors on some documents.'
        },
        {
            item1: 'Can a foreign national be a director in a private limited company?',
            item2: 'Yes, a foreign national can become director of a private limited company. Atleast one director in a company should be resident Indian.'
        },
        {
            item1: 'Can a partnership firm be converted into private limited company?',
            item2: 'Yes, a partnership firm can be converted into private limited company by following the procedure laid down.'
        },
        {
            item1: 'What is Director Identification Number (DIN)?',
            item2: 'DIN is a unique identification number which is allotted to all the directors existing or proposed. DIN can be obtained by filing e-form DIR2 in MCA portal.'
        },
        {
            item1: 'Can a private limited company be later converted into public limited company?',
            item2: 'Yes, You can convert a private limited company into a public limited company.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'BASIC',
            amount: 4999,
            charge: 'all inclusive fees',
            features: [
                'Private limited company registration with 2 DSC',
                'DIN',
                'Incorporation fee',
                'MOA & AOA (Inclusive of all government fee upto 15 lakhs capital)'
            ]
        },
        {
            type: 'PREMIUM',
            amount: 7999,
            charge: 'all inclusive fees',
            features: [
                'Private limited company registration with 2 DSC',
                'DIN',
                'Incorporation fee',
                'MOA & AOA (Inclusive of all government fee upto 15 lakhs capital)',
                'PAN',
                'TAN',
                'Name approval'
            ]
        },
        {
            type: 'SUPREME',
            amount: 11999,
            charge: 'all inclusive fees',
            features: [
                'Private limited company registration with 2 DSC',
                'DIN',
                'Incorporation fee',
                'MOA & AOA (Inclusive of all government fee upto 15 lakhs capital)',
                'PAN',
                'TAN',
                'Name approval',
                'Decleration Of commencement',
                'Share certificates',
                'Auditor appointment'
            ]
        }
    ]
}
