<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Office Locations</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-building-house'></i>
                            <a>Kodambakkam, Chennai</a>
                        </li>
                        <li>
                            <i class='bx bx-home'></i>
                            <a>Tambaram, Chennai</a>
                        </li>
                        <li>
                            <i class='bx bx-home'></i>
                            <a>Nagercoil, Kanyakumari</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:arama.amspl@gmail.com">arama.amspl@gmail.com</a>
                        </li>
                        <li>
                            <i class='bx bx-mobile-alt'></i>
                            <a>80 56 56 9875</a>
                        </li>
                        <li>
                            <i class='bx bx-mobile-alt'></i>
                            <a>84 89 57 7005</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>More</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-credit-card'></i>
                            <a routerLink="/payment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Payment</a>
                        </li>
                        <li>
                            <i class='bx bx-book-reader'></i>
                            <a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">E-Learnings</a>
                        </li>
                        <li>
                            <i class='bx bx-video'></i>
                            <a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Videos</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Office Hours</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-time'></i>
                            <a>Mon-Fri: 8:00 - 19:00</a>
                        </li>
                        <li>
                            <i class='bx bx-time'></i>
                            <a>Sat: 8:00 - 14:00</a>
                        </li>
                        <li>
                            <i class='bx bx-timer'></i>
                            <a>Sun: Closed</a>
                        </li>
                        <li>
                            <i class='bx bx-copyright'></i>
                            <a>Copyright – Arama</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
