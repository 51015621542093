import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-fssai-license',
  templateUrl: './fssai-license.component.html',
  styleUrls: ['./fssai-license.component.scss']
})
export class FssaiLicenseComponent {

    descriptionTitle = 'FSSAI License';
    descriptionContent = 'Our Professionals will help you in getting your FSSAI License and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'FSSAI Registration is a basic registration of food business in the central department of Food Safety and Standards Authority of India. FSSAI license is given to all manufacturers, traders, restaurants carrying out any food business which may involve manufacturing or processing. Food license is issued by the Food Safety and Standards Authority of India (FSSAI), Ministry of Family Health & Welfare, Government of India.',
        'A unique 14- digit license number is issued for FSSAI Registration which has to be quoted on all food packages.FSSAI License can be divided into State level License and Central level License. Hotel, restaurant and medium-sized food manufacturers come under State license. Large size food manufacturers are covered under Central license. Multiple FSSAI licenses are to be taken in case the food is handled in multiple locations.',
        'The Food Safety & Standards Act, 2006 formed to enhance the purity and quality of food. According to this act, none of the business should carry on any food business except under an FSSAI license or FSSAI registration. Any food-related entities of making, processing, packing or distributing any food are now required to obtain an FSSAI License or Registration.'
    ]
    detailDocumentArray = [
        'Photo Identity of Promoters of the Food Business',
        'Food safety management system plan',
        'Partnership Deed / Certificate of Incorporation / Articles of Association / Memorandum of Association',
        'Proof of possession of premises (Rental Agreement / Utility Bill)',
        'Signed and Completed Form-B',
        'List of food products manufactures or processed or stored'
    ];
    detailBenefitArray = [
        'An FSSAI food licence offers legal advantages and promotes goodwill',
        'Additionally, it assists with food safety and the regulation of food production, distribution, storage, sales, and imports.',
        'Before disbursing more money, banks usually run a background check. Thus, having an FSSAI certification helps you obtain loans a little more easily.',
        'Having a FSSAI food licence puts your company in good standing with the government. Expect no legal issues since your business and food products are lawful.'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your FSSAI registration form'
        },
        {
            item1: 'Gathering Documents',
            item2: 'Gathering the necessary documents for the the registration process'
        },
        {
            item1: 'Application Submission',
            item2: 'After successful we will submit the necessary documents to the respective department'
        },
        {
            item1: 'Certificate Issue',
            item2: 'FSSAI certificate will be issued by the respective authority people'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'How to renew FSSAI licence?',
            item2: '30 days prior to the expiry of existing license, via online or by contacting the consultant.'
        },
        {
            item1: 'Does FSSAI license is mandatory for trading packed Tea?',
            item2: 'Yes, FSSAI is mandatory for all kind of food related business.'
        },
        {
            item1: 'Does fruit ripening chamber and cold storage services need to register in FSSAI?',
            item2: 'Yes, the registration under FSSAI is must and the licence depends on the annual turnover.'
        },
        {
            item1: 'If the food related business is done all over the country, How to get the licence from different states?',
            item2: 'The central licence is required in case the business is done all over the country.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 1999,
            charge: 'all inclusive fees',
            features: [
                'FSSAI Certificate'
            ]
        }
    ]

}
