import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-professional-tax-registration',
  templateUrl: './professional-tax-registration.component.html',
  styleUrls: ['./professional-tax-registration.component.scss']
})
export class ProfessionalTaxRegistrationComponent {

    descriptionTitle = 'Professional Tax Registration';
    descriptionContent = 'Our Professionals will help you in getting your professional tax and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Professional Tax is a State – level tax imposed on professions and trades in India. It is mandatorily to be paid by all the staffs employed in private companies. It is the Responsibility of the owner of a company to deduct professional tax from the salaries of his employees and pay the amount collected to the appropriate government department.',
        'In case of salaried employees and wage earners, Employer is liable to deduct professional tax in case of salaried employees. else, the tax is to be paid by the person himself.'
    ]
    detailDocumentArray = [
        'Identity Proof of Directors/Applicant',
        'PAN Card of Directors/ Applicant',
        'Passport size Photo of Director /Applicant',
        'Residential Proof of Registered office',
        'MOA & AOA (in case of company)'
    ];
    detailBenefitArray = [
        'Stop tax wage garnishment',
        'Protection of Assets and Income',
        'Helps you interpret Tax Laws'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your professional tax registration form'
        },
        {
            item1: 'Gathering Documents',
            item2: 'Based on the nature of business we will be gathering documents for processing'
        },
        {
            item1: 'Application Submission',
            item2: 'We will submit all collected details to respective department for the registration'
        },
        {
            item1: 'Certificate Issue',
            item2: 'Registration certificate will be issued by respective department after the approval'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'Can the Tax deducted could be claimed?',
            item2: 'Can be claimed by the Tax payers on account of tax paid.'
        },
        {
            item1: 'When the Professional Tax is Deducted?',
            item2: 'The Tax is deducted in the year in which the tax is actually paid by the employee.'
        },
        {
            item1: 'Who is empowered to impose Professional Tax?',
            item2: 'State Government and Local Authorities.'
        },
        {
            item1: 'What is the maximum amount paid as Professional Tax?',
            item2: 'The Professional Tax does not exceeds INR 2500.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 3999,
            charge: 'all inclusive fees',
            features: [
                'Registered Professional Tax',
            ]
        }
    ]

}
