import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-gst-annual-return-filing',
  templateUrl: './gst-annual-return-filing.component.html',
  styleUrls: ['./gst-annual-return-filing.component.scss']
})
export class GstAnnualReturnFilingComponent {

    descriptionTitle = 'RandomTitle';
    descriptionContent = 'RandomContent';
    detailDescriptionArray = [
        'RandomDescription'
    ]
    detailDocumentArray = [
        'RandomDocument1',
        'RandomDocument2',
        'RandomDocument3',
        'RandomDocument4'
    ];
    detailBenefitArray = [
        'RandomBenefit1',
        'RandomBenefit2',
        'RandomBenefit3',
        'RandomBenefit4'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'RandomDetail1',
            item2: 'RandomDetailDescription1'
        },
        {
            item1: 'RandomDetail2',
            item2: 'RandomDetailDescription2'
        },
        {
            item1: 'RandomDetail3',
            item2: 'RandomDetailDescription3'
        },
        {
            item1: 'RandomDetail4',
            item2: 'RandomDetailDescription4'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'RandomFaq1',
            item2: 'RandomFaqDescrition1'
        },
        {
            item1: 'RandomFaq2',
            item2: 'RandomFaqDescrition2'
        },
        {
            item1: 'RandomFaq3',
            item2: 'RandomFaqDescrition3'
        },
        {
            item1: 'RandomFaq4',
            item2: 'RandomFaqDescrition4'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'BASIC',
            amount: 1000,
            charge: 'all inclusive fees',
            features: [
                'RandomText1',
                'RandomText2',
                'RandomText3'
            ]
        },
        {
            type: 'PREMIUM',
            amount: 2000,
            charge: 'all inclusive fees',
            features: [
                'RandomText1',
                'RandomText2',
                'RandomText3'
            ]
        },
        {
            type: 'SUPREME',
            amount: 3000,
            charge: 'all inclusive fees',
            features: [
                'RandomText1',
                'RandomText2',
                'RandomText3',
                'RandomText1',
                'RandomText2',
                'RandomText3',
                'RandomText1',
                'RandomText2',
                'RandomText3',
                'RandomText1',
                'RandomText2',
                'RandomText3'
            ]
        }
    ]

}
