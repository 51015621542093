import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-limited-liability-partnership',
  templateUrl: './limited-liability-partnership.component.html',
  styleUrls: ['./limited-liability-partnership.component.scss']
})
export class LimitedLiabilityPartnershipComponent {

    descriptionTitle = 'Limited Liability Partnership Registration';
    descriptionContent = 'Our Professionals will help you in getting your Company registered under Companies Act, 2013 and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'The Limited liability Partnership (LLP) was introduced by Parliament of India vide Limited Liability Partnership Act, 2008 to govern LLP business in India. The LLP is a Partnership registered under the Act. The LLP is a separate legal entity and it is completely liable for its assets. In LLP the liability of the partners is limited to their contribution in the business. Thus, the creditors of the limited liability partnership are not the creditors of individual partners.',
        'The advantage of forming an LLP is that the formation is simple. The partners of LLP are not liable to pay the debts of their company from their personal assets. The death, retirement or insolvency of the partners will not affect the life of LLP. It could be winded as per the Provisional act of 2008. A Limited Liability Partnership can be formed to carry a lawful business on a view to earning a profit and cannot be formed for charitable or non-profit purposes.'
    ]
    detailDocumentArray = [
        '2 Photos of each Director',
        'Identity Proof each Director',
        'Office Address Proof',
        'PAN copy of each Director',
        'Address Proof of each Director',
        'Rental Agreement or Sales Deed or Lease Deed'
    ];
    detailBenefitArray = [
        'Limited Liability Partnership has no limit to the maximum number of partners.',
        'The expenses taken for incorporation of an LLP is lesser than that for incorporation of a private or public limited company.',
        'The internal structure of the Organization and management of the business affairs of an LLP are simpler and more flexible.',
        'The compliance of an LLP is lower than that of a private or public limited company.',
        'Dividend Distribution Tax (DDT) is not applicable for LLPs in India.'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Registration with LLP',
            item2: 'Registration of LLP with government'
        },
        {
            item1: 'Obtain DSC & DIN',
            item2: 'DSC and DIN for all directors are required for the documentation of the private company'
        },
        {
            item1: 'Approval and Reservation',
            item2: 'Our Professionals get approval and reservation of any proposed Name through Form-1'
        },
        {
            item1: 'LLP Incorporation',
            item2: 'Filing Form-3 ( LLP Agreement) and Form-4 (appointments, consents of the partners and designated partners)'
        },
        {
            item1: 'Certification of LLP',
            item2: 'Once the registration completed you will get a certificate of LLP in Form-16'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'Requirement of Limited Liability Partnership?',
            item2: 'Two Partners in minimum and no limit to the maximum number of partners. Two Designated Partners in minimum, of whom one must be a resident of India. However, the partners and the designated partners can be the same persons.'
        },
        {
            item1: 'Does a LLP required to file its Annual Returns?',
            item2: 'LLP is required to file only two regulatory compliances every year.'
        },
        {
            item1: 'What is a digital signature certificate (DSC)?',
            item2: 'Digital signature is process to authenticate and validate records electronically. DSC is required for every director of the company as the Ministry of Corporate Affairs (MCA) mandates digital signature of directors on some documents.'
        },
        {
            item1: 'What is Director Identification Number (DIN)?',
            item2: 'DIN is a unique identification number which is allotted to all the directors existing or proposed. DIN can be obtained by filing e-form DIR2 in MCA portal.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 6500,
            charge: 'all inclusive fees',
            features: [
                'LLP Registration',
                '2 DSC and DPIN',
                'Form 1',
                'Form 2 and Form 3',
                'LLP Certificate'
            ]
        }
    ]

}
