import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-copyright-registration',
  templateUrl: './copyright-registration.component.html',
  styleUrls: ['./copyright-registration.component.scss']
})
export class CopyrightRegistrationComponent {

    descriptionTitle = 'Copyright Registration';
    descriptionContent = 'Our Professionals will help you in getting your Copyright Registration and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Copyright is a type of licensed innovation security like trademark and Patents. It is a form of intellectual property. A copyright is a lawful right given by the law to the makers of literary, dramatic, musical and artistic works and producers of cinematograph films and sound recordings. Copyright give the proprietor of the work certain shield to guarantee the scholarly work is ensured and innovativeness is protected.  '
    ]
    detailDocumentArray = [
        'Copies of Work',
        'Application Material',
        'Publication Details',
        'NOC Certificate'
    ];
    detailBenefitArray = [
        'Copyright registration will provide proof of your ownership and relieve you of this legal burden',
        'Copyright holder has rights in a work, those rights, with limited exception, cannot be enforced through the courts',
        'By protecting original work, copyright registration ensures that the reputation of their creators/authors is protected',
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your copyright registration form'
        },
        {
            item1: 'Gathering Materials',
            item2: 'We will gather the materials from you for the copyrights'
        },
        {
            item1: 'Application Filing',
            item2: 'We will be filing application to necessary department'
        },
        {
            item1: 'Diary Number',
            item2: 'We will provide you the diary Number for the approval of copyright'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'What kind of works are Registered under Copyright Registration?',
            item2: 'Literacy, Dramatic, musical, Artistic, Sound Recording, Cinematographic.'
        },
        {
            item1: 'Rights given under Copyright protection in India?',
            item2: 'Right of Publication, Right of Paternity, Right of Integrity.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 2999,
            charge: 'all inclusive fees',
            features: [
                'Registered Copyright',
            ]
        }
    ]


}
