<section class="home-event-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Events</span>
            <h2>Our Events & Due Date Alerts</h2>
            <p>No Worries, No Stress, Leave the Calculations to Us.</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="home-event-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg1">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Tue, 29 November, 2022</span>
                                </div>
                                <h3>MGT 7/7A</h3>
                                <p>MGT 7/7A Filing for Companies & OPC</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="home-event-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg2">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Sat, 31 December, 2022</span>
                                </div>
                                <h3>Income Tax</h3>
                                <p>Revised Income Tax filing for AY 2022-2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="home-event-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg3">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>sat, 31 December, 2022</span>
                                </div>
                                <h3>GST</h3>
                                <p>GST Annual Return filings</p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="home-event-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg4">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Thu, 2 February, 2023</span>
                                </div>
                                <h3>Budget</h3>
                                <p>Budget 2023 Impact Review</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="business-shape7"><img src="assets/img/arama/home/event-shape.png" alt="image"></div>
</section>
