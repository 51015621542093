import { Component } from '@angular/core';
import { Pricing } from 'src/app/components/model/pricing.model';
import { TwoDataSet } from 'src/app/components/model/two-data-set.model';

@Component({
  selector: 'app-pf-registration',
  templateUrl: './pf-registration.component.html',
  styleUrls: ['./pf-registration.component.scss']
})
export class PfRegistrationComponent {

    descriptionTitle = 'PF Registration';
    descriptionContent = 'Our Professionals will help you in getting your PF and help you in getting Business licenses necessary for smooth running of businesses. We will create all the required documents and file them on your behalf';
    detailDescriptionArray = [
        'Employee Provident Fund is a major saving scheme in India for almost all salaried person working in the Government, public or private sector organizations. Employees Provident Fund is a scheme controlled by Employees Provident Funds and Miscellaneous Provision Act,1952. Employees Provident Fund Organisation (EPFO) is the world’s largest security organisation in terms of clientele and the volume of financial transactions undertaken.PF Registration is mandatory for all establishment which employs 20 or more persons. Some establishments having less than 20 employees may voluntarily obtain PF Registration.',
        'A person collecting monthly income less than 15,000 then he is obligatory to become a member of EPF. Employees basic pay more than 15,000 at the time of joining is not required to make PF contribution. Moreover, an employee who is drawing pay of more than Rs 15,000 can still become a member and make PF contributions, with the consent of the Employer.'
    ]
    detailDocumentArray = [
        'Form 01 Employers Registration (may be obtained from ESI Office)',
        'List of minimum 10/20 Employees with their DOJ and Rate of Salary',
        'Copy of PAN Bank a/c existing Certificate',
        'VAT Registration Copy',
        'Factory/Shop & Comm. Est Registration Copy of',
        'Memorandum & Articles of Association with Certificate of Incorporation/Partnersheep Deed as applicable',
        'DD/Cheque for Six month advance contribution (Optional)'
    ];
    detailBenefitArray = [
        'Pension Scheme for EPF account holder',
        'Income Tax exemption',
        'Partial Fund Withdrawals',
        'Loan against PF'
    ];
    detailWeDoArray: TwoDataSet[] = [
        {
            item1: 'Form Drafting',
            item2: 'We will prepare your PF registration form'
        },
        {
            item1: 'Gathering Documents',
            item2: 'Will gather all documents for the registration process'
        },
        {
            item1: 'Form Submission',
            item2: 'For verification we will submit the details to respective department'
        },
        {
            item1: 'ESI Number',
            item2: 'After the successful registration we will provide the PF number'
        }
    ];
    faqArray: TwoDataSet[] = [
        {
            item1: 'What will be the Contribution for PF on the side of Employer & Employee?',
            item2: '12% of Basic Salary & the same amount is contributed by the Employer.'
        },
        {
            item1: 'Is it mandatory for all workers to contribute to the PF?',
            item2: 'Employees with basic salary upto Rs 6500/- mandatorily contribute to the PF and employees with salary above Rs 6501/- an option to PF.'
        },
        {
            item1: 'Form that has to be filled to be a member of PF? ',
            item2: 'Nomination Form No 2.'
        },
        {
            item1: 'Form that has to be filled to transfer PF deposit?',
            item2: 'form no 13 has to be filed.'
        }
    ];
    pricingArray: Pricing[] = [
        {
            type: 'Starting At',
            amount: 4999,
            charge: 'all inclusive fees',
            features: [
                'Registered PF'
            ]
        }
    ]
}
