<div class="home-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="home-about-image">
                    <img src="../../../../../assets/img/arama/home/home-about.png" alt="image">
                    <div class="wrap-shape">
                        <img src="../../../../../assets/img/arama/home/shape.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="home-about-content">
                    <span class="sub-title">About Us</span>
                    <h3>Your Source For Trusted Personal Accounting And Tax Preparation.</h3>
                    <h4>We specialize in helping businesses and individuals "save tax" and "pay less tax" legally.</h4>
                    <p>We provide Accounting and Tax Services that help you to grow your business. We are well known for our quality services, experience, integrity of accounting across India.Our objective is to assist our private and corporate clients, with particular regard to financial planning for long terms success. We provide cost- effective and efficient professional services leveraged by our extensive experience of local business and a high level of personal involvement.Our integrity and confidence made us expertise in the consulating services.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="home-about-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    Accounting Services
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Tax Services
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Licensing
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="home-about-list">
                                <li>
                                    <i class='bx bx-check'></i>
                                    Auditing
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    Payroll Services
                                </li>
                                <li>
                                    <i class='bx bx-check'></i>
                                    GST,Business Registration
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="home-about-btn">
                        <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Start Courses </span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
